import { AnyAction } from 'redux';
import {
    APP_START,
    AUTH_TOKEN_STORE,
    TURN_OFF_INITIAL_WELCOME,
    TURN_ON_INITIAL_WELCOME,
    UPDATE_STILL_THERE_TIMER,
    SET_EXIT_PLAYER_TIMER,
    CLEAR_EXIT_PLAYER_TIMER,
    SET_NAVIGATION_HANDLER,
    VERIFY_PARENTAL_CONTROL_PIN,
    STORE_STATION_ID,
    SET_OVERLAY_NAVIGATION_TYPE,
} from './Appl.types';

export const appStartAction = (): AnyAction => ({
    type: APP_START,
});

export const turnOffInitalWelcomeAction = (): AnyAction => ({
    type: TURN_OFF_INITIAL_WELCOME,
    payload: {
        isWelcomeOn: false,
    },
});

export const storeAuthTokenAction = (authToken: string): AnyAction => ({
    type: AUTH_TOKEN_STORE,
    payload: {
        authToken,
    },
});

export const turnOnInitalWelcomeAction = (): AnyAction => ({
    type: TURN_ON_INITIAL_WELCOME,
    payload: {
        isWelcomeOn: true,
    },
});

export const updateStillThereTimerAction = (stillThereTimer: any): AnyAction => ({
    type: UPDATE_STILL_THERE_TIMER,
    payload: {
        stillThereTimer,
    },
});

export const setExitPlayerTimerAction = (exitPlayerTimer: any): AnyAction => ({
    type: SET_EXIT_PLAYER_TIMER,
    payload: {
        exitPlayerTimer,
    },
});

export const clearExitPlayerTimerAction = (): AnyAction => ({
    type: CLEAR_EXIT_PLAYER_TIMER,
    payload: {
        exitPlayerTimer: null,
    },
});

export const setNavigationHandlerAction = (navigationHandler: string): AnyAction => ({
    type: SET_NAVIGATION_HANDLER,
    payload: {
        navigationHandler,
    },
});

export const verifiedParentalControlPinAction = (): AnyAction => ({
    type: VERIFY_PARENTAL_CONTROL_PIN,
    payload: {
        hasVerifiedParentalControlPin: true,
    },
});

export const storeStationIdAction = (stationId: string): AnyAction => ({
    type: STORE_STATION_ID,
    payload: {
        stationId,
    },
});

export const setOverlayNavigationTypeAction = (overlayNavigationType: string): AnyAction => ({
    type: SET_OVERLAY_NAVIGATION_TYPE,
    payload: {
        overlayNavigationType,
    },
});

import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './InfoTitle.scss';

interface InfoTitleProps {
    title: string;
}

const InfoTitle: FunctionComponent<InfoTitleProps> = (props): JSX.Element => {
    const { title } = props;
    const titleRef = useRef<HTMLDivElement>();
    const [isTitleOverflow, setIsTitleOverflow] = useState(false);
    const titleFontLarge = '54px';
    const titleFontSmall = '49px';
    const maxTitleHeight = 120;
    const minTitleHeight = 60;
    const titleOverflowHeight = '110px';

    useEffect(() => {
        const currentDivRef = titleRef?.current;
        if (currentDivRef) {
            currentDivRef.style.maxHeight = 'none';
            currentDivRef.style.fontSize = titleFontLarge;
            setIsTitleOverflow(false);
            if (currentDivRef.clientHeight && currentDivRef.clientHeight > minTitleHeight) {
                currentDivRef.style.fontSize = titleFontSmall;
                if (
                    currentDivRef.clientHeight < maxTitleHeight &&
                    currentDivRef.clientHeight > minTitleHeight
                ) {
                    currentDivRef.style.fontSize = titleFontLarge;
                }
                if (currentDivRef.clientHeight > maxTitleHeight) {
                    currentDivRef.style.fontSize = titleFontSmall;
                    if (currentDivRef.clientHeight > maxTitleHeight) setIsTitleOverflow(true);
                    currentDivRef.style.maxHeight = titleOverflowHeight;
                    currentDivRef.style.overflow = 'hidden';
                }
            }
        }
    }, [title]);

    return (
        <div className="info-title-container">
            <div
                className={`info-title ${isTitleOverflow ? 'mask-title' : ''}`}
                ref={titleRef as React.RefObject<HTMLDivElement>}
            >
                {title}
            </div>
            {isTitleOverflow && <div className="partial-truncation">{title}</div>}
        </div>
    );
};

InfoTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default InfoTitle;

import { AnyAction } from 'redux';
import {
    CANT_PLAY_OVERLAY_SHOW,
    CANT_PLAY_OVERLAY_HIDE,
    STILL_THERE_OVERLAY_SHOW,
    STILL_THERE_OVERLAY_HIDE,
    INTERNET_CONNECTION_OVERLAY_SHOW,
    INTERNET_CONNECTION_OVERLAY_HIDE,
} from './MessageOverlay.types';

export const cantPlayOverlayShowAction = (): AnyAction => ({
    type: CANT_PLAY_OVERLAY_SHOW,
    payload: {
        isCantPlayOverlayActive: true,
    },
});

export const cantPlayOverlayHideAction = (): AnyAction => ({
    type: CANT_PLAY_OVERLAY_HIDE,
    payload: {
        isCantPlayOverlayActive: false,
    },
});

export const stillThereOverlayShowAction = (): AnyAction => ({
    type: STILL_THERE_OVERLAY_SHOW,
    payload: {
        isStillThereOverlayActive: true,
    },
});

export const stillThereOverlayHideAction = (): AnyAction => ({
    type: STILL_THERE_OVERLAY_HIDE,
    payload: {
        isStillThereOverlayActive: false,
    },
});

export const internetConnectionLostOverlayShowAction = (): AnyAction => ({
    type: INTERNET_CONNECTION_OVERLAY_SHOW,
    payload: {
        isInternetConnectionLostOverlayActive: true,
    },
});

export const internetConnectionLostOverlayHideAction = (): AnyAction => ({
    type: INTERNET_CONNECTION_OVERLAY_HIDE,
    payload: {
        isInternetConnectionLostOverlayActive: false,
    },
});

import React, { FunctionComponent, useEffect } from 'react';

const MouseEvents: FunctionComponent = (): JSX.Element => {
    const onClickHandler = (e: Event) => {
        if (e instanceof MouseEvent) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    useEffect(() => {
        document.addEventListener('click', onClickHandler, true);
        return () => {
            document.removeEventListener('click', onClickHandler, true);
        };
    }, []);

    return <></>;
};

export default MouseEvents;

import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { RootState } from '../../../store/root.reducers';
import './StandardInfo.scss';
import { MetadataFormatter } from '../../../core/infrastructure/MetadataFormatter';
import { InfoTitle } from '../InfoTitle';

interface StandardInfoProps {
    id: string;
}

const StandardInfo: FunctionComponent<StandardInfoProps> = (props): JSX.Element => {
    const { id } = props;
    const metaData = useSelector((state: RootState) => state.metaData.metaData[0]);
    const channelData = useSelector((state: RootState) => state.stationData.channelData[0]);
    const currentTime = useSelector((state: RootState) => state.infoBanner.currentTime);
    const dataFormatter = new MetadataFormatter(metaData, channelData);

    const subtitleDiv = dataFormatter.hasSubtitle() ? (
        <div className="info_subtitle">{dataFormatter.getSubtitle()}</div>
    ) : null;

    const channelLogoClassName = dataFormatter.hasSubtitle()
        ? 'info_channelLogoBelowSubtitle'
        : 'info_channelLogoBelowTitle';

    // TODO: call metadata when hideLinearSchedules is False
    // const showStandartInfo = dataFormatter.isHideLinearSchedules();

    return (
        <div id={`${id}-standard-info-banner`} className={'standard-info'}>
            <div className="info_standardbg" />
            <div className="info_left-container">
                <InfoTitle title={dataFormatter.getTitle()} />
                {subtitleDiv}
                <img
                    src={dataFormatter.getChannelLogoUrl()}
                    className={channelLogoClassName}
                    style={{ display: 'inline' }}
                />
            </div>
            <div className="info_right-container">
                <img className="info_brandingicon" />
                <div className="info_clock">
                    {dataFormatter.createFormattedAMPMTime(currentTime)}
                </div>
            </div>
        </div>
    );
};

StandardInfo.propTypes = {
    id: PropTypes.string.isRequired,
};
export default StandardInfo;

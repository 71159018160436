import { AnyAction } from 'redux';
import { ShakaPlayerImpl } from '../../../views/ShakaPlayer/impl/ShakaPlayerImpl';
import { OverlayNavigationTypes } from '../../../core/infrastructure/navigation/OverlayNavigationTypes';
import { setOverlayNavigationType } from '../../appl/Appl.reducers';
import { setOverlayHighlightVerticalPositionAction } from '../overlayhighlight/overlayhighlight.actions';
import {
    hideAudioTrackOverlayAction,
    showAudioTrackOverlayAction,
} from './audiotrackoverlay.actions';
import { HIDE_AUDIO_TRACK_OVERLAY, SHOW_AUDIO_TRACK_OVERLAY } from './audiotrackoverlay.types';

const initialState: {
    isAudioTrackOverlayActive: boolean;
    audioTrackList: [];
} = {
    isAudioTrackOverlayActive: false,
    audioTrackList: [],
};

const audioTrackeOverlayReducer = (state = initialState, { type, payload } = <AnyAction>{}) => {
    switch (type) {
        case SHOW_AUDIO_TRACK_OVERLAY:
        case HIDE_AUDIO_TRACK_OVERLAY:
            return {
                ...state,
                isAudioTrackOverlayActive: payload.isAudioTrackOverlayActive,
                audioTrackList: payload.audioTrackList,
            };

        default:
            return state;
    }
};

export const showAudioTrackOverlay = () => (dispatch: Function) => {
    dispatch(showAudioTrackOverlayAction(ShakaPlayerImpl.getInstance().getAudioTrackModel()));
    dispatch(setOverlayHighlightVerticalPositionAction(0));
    dispatch(setOverlayNavigationType(OverlayNavigationTypes.AUDIO_TRACK_OVERLAY));
};

export const hideAudioTrackOverlay = () => (dispatch: Function) => {
    dispatch(hideAudioTrackOverlayAction());
};

export const onTrackSelect = (selectedIndex: number) => (dispatch: Function) => {
    ShakaPlayerImpl.getInstance().setCurrentAudioTrack(selectedIndex);
    dispatch(hideAudioTrackOverlay());
};

export default audioTrackeOverlayReducer;

// TODO: Actual implementation needs to be done.
export default class ParentalControlImpl {
    private parentalControlPin: string;

    constructor(parentalControlPin: string) {
        // TODO: Implementation to fetch parental control pin
        this.parentalControlPin = parentalControlPin;
    }

    verify(userInputPCPin: string): boolean {
        return this.parentalControlPin === userInputPCPin;
    }
}

import { AnyAction } from 'redux';
import { Diagnostics, Payload } from '@tivocorp/tivo-webapp-loggers';
import { OverlayNavigationTypes } from '../../core/infrastructure/navigation/OverlayNavigationTypes';
import { AnalyticsLogger } from '../../core/logging/AnalyticsLogger';
import {
    APP_START,
    AUTH_TOKEN_STORE,
    TURN_OFF_INITIAL_WELCOME,
    TURN_ON_INITIAL_WELCOME,
    SET_EXIT_PLAYER_TIMER,
    UPDATE_STILL_THERE_TIMER,
    SET_NAVIGATION_HANDLER,
    VERIFY_PARENTAL_CONTROL_PIN,
    STORE_STATION_ID,
    SET_OVERLAY_NAVIGATION_TYPE,
} from './Appl.types';
import {
    appStartAction,
    clearExitPlayerTimerAction,
    setExitPlayerTimerAction,
    storeAuthTokenAction,
    turnOnInitalWelcomeAction,
    updateStillThereTimerAction,
    setNavigationHandlerAction,
    turnOffInitalWelcomeAction,
    verifiedParentalControlPinAction,
    storeStationIdAction,
    setOverlayNavigationTypeAction,
} from './Appl.actions';
import { fetchStationData } from '../stationdata/StationData.reducers';
import {
    cantPlayOverlayShow,
    internetConnectionLostOverlayHide,
    internetConnectionLostOverlayShow,
    stillThereOverlayHide,
    stillThereOverlayShow,
} from '../messageoverlay/MessageOverlay.reducers';
import { EXIT_PLAYER_THRESHOLD_TIME, STILL_THERE_THRESHOLD_TIME } from '../../constants';
import { Log } from '../../core/logging/Log';
import { NavigationHandlers } from '../../core/infrastructure/navigation/NavigationTypes';
import { PARENTAL_CONTROL_PIN } from '../../strings';
import {
    enterPinOverlayHide,
    enterPinOverlayShow,
    invalidParentalControlPin,
    validParentalControlPin,
} from '../pinoverlay/PinOverlay.reducers';
import ParentalControlImpl from '../../core/infrastructure/parentalcontrol/ParentalControlImpl';
import { fetchMetaData } from '../metadata/MetaData.reducers';
import { QoEAppLogger } from '../../core/logging/QoEAppLogger';
import { IDeviceParams } from '../../core/logging/IDeviceParams';
import { startVideoPlayback } from '../shakaplayer/ShakaPlayer.reducers';
import { storeStationDataAction } from '../stationdata/StationData.actions';
import { DeviceModel } from '../../core/device';
import { SageLogger } from '../../core/logging/SageLogger';
import { ENABLE_DIAGNOTICS_LOGGING, SAGE_LOGGING_VERSION } from '../../config';
import { Locale } from '../../core/locale';
import { AppUtil } from '../../core/utils/AppUtil';

const TAG = 'appl';
const parentalControl = new ParentalControlImpl(PARENTAL_CONTROL_PIN);

const initialState: {
    isWelcomeOn: boolean;
    authToken: string;
    stillThereTimer: any;
    exitPlayerTimer: any;
    navigationHandler: string;
    hasVerifiedParentalControlPin: boolean;
    stationId: string;
    overlayNavigationType: string;
} = {
    isWelcomeOn: true,
    authToken: '',
    stillThereTimer: null,
    exitPlayerTimer: null,
    navigationHandler: NavigationHandlers.ApplHandler,
    hasVerifiedParentalControlPin: false,
    stationId: '',
    overlayNavigationType: OverlayNavigationTypes.DEFAULT,
};

const applReducer = (state = initialState, { type, payload } = <AnyAction>{}) => {
    switch (type) {
        case APP_START:
            return state;

        case TURN_ON_INITIAL_WELCOME:
        case TURN_OFF_INITIAL_WELCOME:
            return {
                ...state,
                isWelcomeOn: payload.isWelcomeOn,
            };

        case AUTH_TOKEN_STORE:
            return {
                ...state,
                authToken: payload.authToken,
            };

        case UPDATE_STILL_THERE_TIMER:
            return {
                ...state,
                stillThereTimer: payload.stillThereTimer,
            };

        case SET_EXIT_PLAYER_TIMER:
            return {
                ...state,
                exitPlayerTimer: payload.exitPlayerTimer,
            };

        case SET_NAVIGATION_HANDLER:
            return {
                ...state,
                navigationHandler: payload.navigationHandler,
            };

        case VERIFY_PARENTAL_CONTROL_PIN:
            return {
                ...state,
                hasVerifiedParentalControlPin: payload.hasVerifiedParentalControlPin,
            };

        case STORE_STATION_ID:
            return {
                ...state,
                stationId: payload.stationId,
            };
        case SET_OVERLAY_NAVIGATION_TYPE:
            return {
                ...state,
                overlayNavigationType: payload.overlayNavigationType,
            };

        default:
            return state;
    }
};

const initializeVULogging = () => {
    const segmentDeviceParams = {
        app: {
            name: DeviceModel.getAppName(),
            version: DeviceModel.getAppVersion(),
        },
        applicationSessionId: AppUtil.getSessionID(),
        connection: DeviceModel.getConnection(),
        device: {
            id: DeviceModel.getDeviceId(),
            type: DeviceModel.getDeviceType(),
            adTrackingEnabled: DeviceModel.getAdTrackingEnabled(),
        },
        deviceTsn: DeviceModel.getTsn(),
        locale: DeviceModel.getLocale(),
        loggingVersion: SAGE_LOGGING_VERSION,
        msoName: DeviceModel.getMsoName(),
        msoPartnerId: DeviceModel.getMsoPartnerId(),
        timezone: AppUtil.getUserTimezone(),
        userId: DeviceModel.getUserId(),
    };
    const globalPayload: Payload = new Payload(segmentDeviceParams);
    AnalyticsLogger.createUserId(DeviceModel.getTsn());
    AnalyticsLogger.setSegmentGlobalParams(globalPayload);
    AnalyticsLogger.logIdentifyCall();
};

export const appStart = () => (dispatch: Function) => {
    DeviceModel.initialize();
    Locale.setLocale(DeviceModel.getLocale());
    const deviceParams: IDeviceParams = {
        adTrackingEnabled: DeviceModel.getAdTrackingEnabled(),
        appName: DeviceModel.getAppName(),
        appVersion: DeviceModel.getAppVersion(),
        connection: DeviceModel.getConnection(),
        deviceId: DeviceModel.getDeviceId(),
        deviceTsn: DeviceModel.getTsn(),
        deviceType: DeviceModel.getDeviceType(),
        loggingVersion: SAGE_LOGGING_VERSION,
        msoName: DeviceModel.getMsoName(),
        msoPartnerId: DeviceModel.getMsoPartnerId(),
        userId: DeviceModel.getUserId(),
    };

    // Diagnostic log initilization
    const globalPayload: Payload = new Payload(deviceParams);
    if (ENABLE_DIAGNOTICS_LOGGING) {
        Diagnostics.setGlobals(globalPayload);
        Diagnostics.getInstance().setTransport(SageLogger.getDiagnosticsTransport());
    } else {
        Diagnostics.disabled();
    }
    Diagnostics.noConsole();
    Diagnostics.info(TAG, 'Application Start');

    // QOE logging intialization
    QoEAppLogger.setGlobalParams(deviceParams);
    QoEAppLogger.setSageTransportParams(SageLogger.getOperationsTransport());
    QoEAppLogger.logApplicationStartEvent();

    // Vu logging intialization
    initializeVULogging();
    AnalyticsLogger.logAppEntryEvent();

    dispatch(appStartAction());
    dispatch(turnOnInitalWelcomeAction());

    // TODO: Actual implementation of Enter Pin Overlay yet to be decided
    const pinStatus = DeviceModel.getPinStatus() === 'true';

    if (pinStatus) {
        dispatch(enterPinOverlayShow());
    } else {
        dispatch(continuePlayback());
    }
};

export const updateStillThereTimer = () => (dispatch: Function, getState: Function) => {
    const { appl, messageOverlay, pinOverlay } = getState();
    const { stillThereTimer, exitPlayerTimer } = appl;
    const { isStillThereOverlayActive } = messageOverlay;
    const { isEnterPinOverlayActive } = pinOverlay;
    if (!isEnterPinOverlayActive) {
        if (isStillThereOverlayActive) dispatch(stillThereOverlayHide());
        if (stillThereTimer) clearTimeout(stillThereTimer);
        if (exitPlayerTimer) clearTimeout(exitPlayerTimer);
        const timer = setTimeout(() => {
            Log.i(TAG, 'Reached the still there time limit.');
            dispatch(stillThereOverlayShow());
            dispatch(setExitPlayerTimer());
        }, STILL_THERE_THRESHOLD_TIME);
        dispatch(updateStillThereTimerAction(timer));
    }
};

export const setExitPlayerTimer = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    const { exitPlayerTimer } = appl;
    if (exitPlayerTimer) clearTimeout(exitPlayerTimer);
    const timer = setTimeout(() => {
        // TODO: Implement the return to home app
        Log.i(TAG, 'Return to home app.');
        dispatch(stillThereOverlayHide());
    }, EXIT_PLAYER_THRESHOLD_TIME);
    Diagnostics.info(TAG, 'Return to home app.');
    dispatch(setExitPlayerTimerAction(timer));
};

export const clearExitPlayerTimer = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    const { exitPlayerTimer } = appl;
    if (exitPlayerTimer) clearTimeout(exitPlayerTimer);
    dispatch(clearExitPlayerTimerAction());
};

export const setNavigationHandler = (handler: string) => (dispatch: Function) => {
    dispatch(setNavigationHandlerAction(handler));
};

export const turnOffInitalWelcome = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    /* This is called every time Playing event is received from shaka.
    Added check Just to make sure to call it only once */
    if (appl.isWelcomeOn) {
        dispatch(turnOffInitalWelcomeAction());
        if (
            appl.navigationHandler !== NavigationHandlers.ActionStripHandler &&
            appl.navigationHandler !== NavigationHandlers.OverlayNavigationHandler
        )
            dispatch(setNavigationHandler(NavigationHandlers.ApplHandler));
    }
};

export const internetConnectionLost = () => (dispatch: Function, getState: Function) => {
    const { pinOverlay } = getState();
    const { isEnterPinOverlayActive } = pinOverlay;
    if (isEnterPinOverlayActive) dispatch(enterPinOverlayHide());
    dispatch(internetConnectionLostOverlayShow());
};

export const internetConnectionBack = () => (dispatch: Function, getState: Function) => {
    const { appl, metaData } = getState();
    const { hasVerifiedParentalControlPin } = appl;
    const { metaData: currentMetadata } = metaData;
    const isMetaDataUpdateRequired =
        currentMetadata.length && currentMetadata[0].endTimestamp * 1000 - new Date().getTime() < 0;
    dispatch(setNavigationHandler(NavigationHandlers.ApplHandler));
    dispatch(internetConnectionLostOverlayHide());
    const pinStatus = DeviceModel.getPinStatus() === 'true';
    if (pinStatus && !hasVerifiedParentalControlPin) {
        dispatch(enterPinOverlayShow());
    }
    if (isMetaDataUpdateRequired) dispatch(fetchMetaData());
    AnalyticsLogger.logStreamingRecoveryEvent('NETWORK_LOST');
    QoEAppLogger.logWatchContentStartEvent();
    QoEAppLogger.logStreamingStartEvent();
};

export const verifyParentalControlPin = () => (dispatch: Function, getState: Function) => {
    const { pinOverlay } = getState();
    const { parentalControlPin } = pinOverlay;

    if (parentalControl.verify(parentalControlPin.join(''))) {
        dispatch(verifiedParentalControlPinAction());
        dispatch(validParentalControlPin());
        dispatch(enterPinOverlayHide());
        dispatch(setNavigationHandler(NavigationHandlers.ApplHandler));
        dispatch(continuePlayback());
    } else {
        dispatch(invalidParentalControlPin());
        dispatch(setNavigationHandler(NavigationHandlers.PinDialogHandler));
    }
};

const continuePlayback = () => (dispatch: Function) => {
    dispatch(updateStillThereTimer());
    if (DeviceModel.getStationId()) {
        dispatch(storeStationIdAction(DeviceModel.getStationId()));
        dispatch(storeAuthTokenAction(DeviceModel.getDomainToken()));
        dispatch(fetchStationData());
    } else if (DeviceModel.getStreamUrl()) {
        dispatch(startVideoPlayback(DeviceModel.getStreamUrl()));
        dispatch(storeStationDataAction([], DeviceModel.getStreamUrl()));
    } else dispatch(cantPlayOverlayShow());
};

export const setOverlayNavigationType = (overlayNavigation: string) => (dispatch: Function) => {
    dispatch(setOverlayNavigationTypeAction(overlayNavigation));
};

export default applReducer;

export const APP_START = 'APP_START';
export const TURN_OFF_INITIAL_WELCOME = 'TURN_OFF_INITIAL_WELCOME';
export const TURN_ON_INITIAL_WELCOME = 'TURN_ON_INITIAL_WELCOME';
export const AUTH_TOKEN_STORE = 'AUTH_TOKEN_STORE';
export const UPDATE_STILL_THERE_TIMER = 'UPDATE_STILL_THERE_TIMER';
export const SET_EXIT_PLAYER_TIMER = 'SET_EXIT_PLAYER_TIMER';
export const CLEAR_EXIT_PLAYER_TIMER = 'CLEAR_EXIT_PLAYER_TIMER';
export const SET_NAVIGATION_HANDLER = 'SET_NAVIGATION_HANDLER';
export const VERIFY_PARENTAL_CONTROL_PIN = 'VERIFY_PARENTAL_CONTROL_PIN';
export const STORE_STATION_ID = 'STORE_STATION_ID';
export const SET_OVERLAY_NAVIGATION_TYPE = 'SET_OVERLAY_NAVIGATION_TYPE';

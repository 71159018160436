/**
 * class  for MetaData object.
 */
export class MetaData {
    /**
     * Station id
     */
    stationId: string;

    /**
     * Content id
     */
    contentId: string;

    /**
     * Duration
     */
    duration: string;

    /**
     * Title
     */
    title: string;

    /**
     * Short description
     */
    shortDescription: string;

    /**
     * Series id
     */
    seriesId: string;

    /**
     * Original air date
     */
    originalAirDate: string;

    /**
     * Release year
     */
    releaseYear: string;

    /**
     * Episode number
     */
    episodeNumber: string;

    /**
     * Episode title
     */
    episodeTitle: string;

    /**
     * Content type
     */
    contentType: string;

    /**
     * Season number
     */
    seasonNumber: string;

    /**
     * Ratings
     */
    ratings: [];

    /**
     * Genres
     */
    genres: [string];

    /**
     * Start timestamp
     */
    startTimestamp: number;

    /**
     * End timestamp
     */
    endTimestamp: number;

    /**
     * Critic rating
     */
    criticRating: [Map<string, string>];

    constructor(metaData: any) {
        this.title = metaData.title;
        this.shortDescription = metaData.description;
        this.contentType = metaData.contentType;
        this.startTimestamp = metaData.startTimestamp;
        this.endTimestamp = metaData.endTimestamp;
        this.duration = metaData.duration;
        this.stationId = metaData.stationId;
        this.contentId = metaData.contentId;
        this.ratings = metaData.ratings;
        this.seriesId = metaData.seriesId;
        this.originalAirDate = metaData.originalAirDate;
        this.releaseYear = metaData.releaseYear;
        this.episodeTitle = metaData.episodeTitle;
        this.episodeNumber = metaData.episodeNumber;
        this.seasonNumber = metaData.seasonNumber;
        this.genres = metaData.genres;
        this.criticRating = metaData.criticRating;
    }
}

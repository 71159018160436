import { IMetaDataRepository } from '../../entities/metadata/IMetaDataRepository';
import { MetaData } from '../../entities/metadata/MetaData';

export class MetaDataImpl implements IMetaDataRepository {
    metaDataRepo: IMetaDataRepository;

    constructor(metaData: IMetaDataRepository) {
        this.metaDataRepo = metaData;
    }

    async getMetaData(): Promise<MetaData[]> {
        return this.metaDataRepo.getMetaData();
    }
}

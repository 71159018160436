import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/root.reducers';
import { ActionItemProps } from './ActionItemProps';
import { TURN_SUBTITLES_CC_ON } from '../../../../store/infobanner/ActionsStripNavigation.types';
import './ActionItem.scss';

const ActionItem: FunctionComponent<ActionItemProps> = (props): JSX.Element => {
    const { id, name, highlighted } = props;
    const { cc: isCCOn } = useSelector((state: RootState) => state.shakaPlayer);
    return (
        <div className={`action-item ${highlighted ? 'highlighted-action-item' : ''}`}>
            {id === TURN_SUBTITLES_CC_ON && isCCOn ? <img className="check-mark" /> : null}
            <span>{name}</span>
        </div>
    );
};

ActionItem.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    highlighted: PropTypes.bool.isRequired,
};

export default ActionItem;

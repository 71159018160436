import { AnyAction } from 'redux';
import {
    HIDE_SUBTITLE_AND_CC_LANGUAGE_OVERLAY,
    SHOW_SUBTITLE_AND_CC_LANGUAGE_OVERLAY,
} from './subtitleandcclanguageoverlay.types';

export const showSubtitleAndCCLanguageOverlayAction = (subtitleLangList: any): AnyAction => ({
    type: SHOW_SUBTITLE_AND_CC_LANGUAGE_OVERLAY,
    payload: {
        isSubtitleAndCCLanguageOverlayActive: true,
        subtitleLangaugeList: subtitleLangList,
    },
});

export const hideSubtitleAndCCLanguageOverlayAction = (): AnyAction => ({
    type: HIDE_SUBTITLE_AND_CC_LANGUAGE_OVERLAY,
    payload: {
        isSubtitleAndCCLanguageOverlayActive: false,
        subtitleLangaugeList: [],
    },
});

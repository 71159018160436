import { useDispatch } from 'react-redux';
import { Diagnostics } from '@tivocorp/tivo-webapp-loggers';
import { TrackUtils } from '../../../core/utils/TrackUtils';
import { Log } from '../../../core/logging/Log';
import { AnalyticsLogger, QoEAppLogger } from '../../../core/logging';
import { turnOffInitalWelcome } from '../../../store/appl/Appl.reducers';
import { IVideoPlayer } from '../interfaces/IVideoPlayer';
import shakaConfig from '../ShakaPlayerConfig';
import { Constants } from '../../../core/constants';
import { handleVideoPlayerError } from '../../../store/shakaplayer/ShakaPlayer.reducers';

// TODO: to be used in release build
const shaka = require('shaka-player/shaka-player.compiled');

// TODO: to be removed in release build
// const shaka = require('shaka-player/shaka-player.compiled.debug');

shaka.polyfill.installAll();

export class ShakaPlayerImpl implements IVideoPlayer {
    private static oIVideoPlayer: IVideoPlayer;

    private static TAG = 'ShakaPlayerImpl';

    private subtitleTracks: any[] = [];

    private mSelectedTextTrackId: number = -1;

    private mAudioTracks: any[] = [];

    private mAudioTrackIds: number[] = [];

    public mVideoPlayer: any;

    private mAudioTracksMap: Map<number, string> = new Map();

    public timer;

    // currently shakaPlayer don't have listener to detect firstFrame event
    // this flag is required to log firstframe event.
    public isFirstFrameTriggered = false;

    private dispatch = useDispatch();

    public constructor() {
        this.mVideoPlayer = new shaka.Player();
        this.setUpPlayer();
    }

    configure(config: any): void {
        this.mVideoPlayer.attach(config.playerEl);
        this.mVideoPlayer.setVideoContainer(config.playerRootEl);
        this.registerEventListeners(config.playerEl);
    }

    static getInstance(): IVideoPlayer {
        if (!ShakaPlayerImpl.oIVideoPlayer) ShakaPlayerImpl.oIVideoPlayer = new ShakaPlayerImpl();
        return ShakaPlayerImpl.oIVideoPlayer;
    }

    setAudioTrackByID(audioID: number): number {
        if (this.mAudioTrackIds.length > 0) {
            let track = this.mAudioTracks.find(({ audioId }) => audioId === audioID);
            // audioId isn't available so pick the first one
            if (!track) {
                track = this.mAudioTracks.indexOf(0);
            }
            this.mVideoPlayer.selectVariantTrack(track, true);
            // close streaming for old track and log start events for new track
            QoEAppLogger.setEndReasonAndLogEvent(
                QoEAppLogger.PlaybackEndReasons.AUDIO_TRACK_CHANGE,
            );
            return track.id;
        }
        return -1;
    }

    private getAudioLanguages(): string[] {
        return this.mVideoPlayer.getAudioLanguages();
    }

    getVariants(): any[] {
        return this.mVideoPlayer.getVariantTracks();
    }

    getCurrentAudioTrack(): any {
        return this.getVariants().find(({ active }) => active === true);
    }

    getAudioTrackCount(): number {
        return this.mAudioTracksMap.size;
    }

    getAudioTrackModel(): any[] {
        // this will give list of audio tracks and
        // selected track will always be on the top
        if (this.getAudioTrackCount() === 0) {
            this.mAudioTracksMap = TrackUtils.getInstance().getAudioTrackMap(
                this.getSupportedAudioTracks(),
            );
        }
        const currTrack = this.getCurrentAudioTrack();
        const currId = currTrack ? currTrack.audioId : -1;
        const audioTracksForOverlay: any[] = [];
        this.mAudioTrackIds = [];
        if (this.mAudioTracksMap.size > 1) {
            // track a[key, value] => key is audioId and value is language string
            const mapToArray = [...Array.from(this.mAudioTracksMap)];
            this.mAudioTracksMap = new Map(mapToArray.sort((a, b) => a[1].localeCompare(b[1])));
        }

        this.mAudioTracksMap.forEach((value, key) => {
            if (key === currId) {
                this.mAudioTrackIds.unshift(key);
                audioTracksForOverlay.unshift({
                    text: value,
                    isSelected: true,
                });
            } else {
                this.mAudioTrackIds.push(key);
                audioTracksForOverlay.push({
                    text: value,
                    isSelected: false,
                });
            }
        });
        return audioTracksForOverlay;
    }

    getSupportedAudioTracks(): any[] {
        if (this.mAudioTracks.length === 0) {
            const variants = this.getVariants();
            /* find unique combination of Language and audioId
             * from the variants to fetch multiple tracks of
             * same Language
             */
            Log.i(ShakaPlayerImpl.TAG, `variants `, variants);
            Diagnostics.info(ShakaPlayerImpl.TAG, `{variants: `, variants, '}');
            this.getAudioLanguages().forEach((lang: string) => {
                const audioIds: number[] = [];
                variants.forEach((variant: any) => {
                    if (!audioIds.includes(variant.audioId) && variant.language === lang) {
                        audioIds.push(variant.audioId);
                        this.mAudioTracks.push(variant);
                    }
                });
            });
        }
        Log.i(ShakaPlayerImpl.TAG, `Found Supported Tracks`, this.mAudioTracks);
        return this.mAudioTracks;
    }

    setCurrentAudioTrack(selectedIndex: number): void {
        if (this.getAudioTrackCount() > selectedIndex) {
            const audioId = this.mAudioTrackIds[selectedIndex];
            Diagnostics.info(ShakaPlayerImpl.TAG, `setCurrentAudioTrack`, audioId);
            Log.i(ShakaPlayerImpl.TAG, `setCurrentAudioTrack`, audioId);
            const currTrack = this.getCurrentAudioTrack();
            if (currTrack && audioId !== currTrack.audioId) this.setAudioTrackByID(audioId);
        }
    }

    private setUpPlayer() {
        if (this.mVideoPlayer && shakaConfig) {
            this.mVideoPlayer.configure(shakaConfig);
        }
    }

    isTextTrackSupported(): boolean {
        return this.getSupportedSubtitles().length > 0;
    }

    isTextTrackChangeSupported(): boolean {
        return this.getSupportedSubtitles().length > 1;
    }

    getSupportedSubtitles(): any[] {
        return this.mVideoPlayer.getTextTracks();
    }

    setSubtitlesById(selectedId: number): number {
        if (this.subtitleTracks.length === 0) {
            Log.d(ShakaPlayerImpl.TAG, `No supported Subtitle available`);
            return -1;
        }
        this.displaySubtitles(true);
        const selectedTrack = this.subtitleTracks.find(({ id }) => id === selectedId);
        this.mVideoPlayer.selectTextTrack(selectedTrack);
        Diagnostics.info(ShakaPlayerImpl.TAG, `setCurrentTextTrack`, selectedTrack.id);
        return selectedTrack.id;
    }

    private getLanguageByCode = (code: string) => {
        return TrackUtils.getInstance().getLanguageByCode(code, Constants.ORIGINAL_LANGUAGE);
    };

    setCurrentTextTrack(selectedIndex: number): void {
        if (this.isTextTrackSupported()) {
            this.mSelectedTextTrackId = this.subtitleTracks[selectedIndex]?.id;
            this.setSubtitlesById(this.mSelectedTextTrackId);
        }
    }

    getTextTrackModel(): any[] {
        // this will give list of subtitleTracks and
        // selected track always will be on the top

        this.subtitleTracks = this.getSupportedSubtitles().sort((trackItem1, trackItem2) => {
            return (
                Number(trackItem2.id === this.mSelectedTextTrackId) -
                Number(trackItem1.id === this.mSelectedTextTrackId)
            );
        });
        const textTracksForOverlay: any[] = [];
        this.subtitleTracks.forEach((textTrack, index) => {
            textTracksForOverlay.push({
                text: this.getLanguageByCode(textTrack.language),
                isSelected: index === 0,
            });
        });
        return textTracksForOverlay;
    }

    displaySubtitles = (display: boolean) => {
        this.mVideoPlayer.setTextTrackVisibility(display);
    };

    registerEventListeners = (currVideoRef: HTMLVideoElement) => {
        currVideoRef.addEventListener('playing', (event) => {
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: Playing');
            QoEAppLogger.updateHealthStatsMetricsByEvent({
                type: 'play',
                timeStamp: event.timeStamp,
            });
            this.dispatch(turnOffInitalWelcome());
            if (!this.isFirstFrameTriggered) {
                QoEAppLogger.logFirstFrameEvent();
                AnalyticsLogger.logWatchStartedEvent();
                this.isFirstFrameTriggered = true;

                QoEAppLogger.setIntialMetricsFromPlayerStats(this.mVideoPlayer.getStats());
                // log mediaHealth event and schedule event for every 5 minutes
                this.timer = setInterval(() => {
                    QoEAppLogger.updateAllHealthStatsMetrics(this.mVideoPlayer.getStats());
                    QoEAppLogger.logMediaHealthEvent();
                }, QoEAppLogger.MEDIA_HEALTH_EVENT_INTERVAL);
            }
        });

        currVideoRef.addEventListener('play', (event) => {
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: play');
            QoEAppLogger.updateHealthStatsMetricsByEvent(event);
        });

        currVideoRef.addEventListener('pause', (event) => {
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: pause');
            QoEAppLogger.updateHealthStatsMetricsByEvent(event);
        });

        currVideoRef.addEventListener('stalled', (event) => {
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: stalled');
            QoEAppLogger.updateHealthStatsMetricsByEvent(event);
        });

        this.mVideoPlayer.addEventListener('trackschanged', () => {
            Diagnostics.info(ShakaPlayerImpl.TAG, '{PlayerEvent: trackschanged}');
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: trackschanged');
        });

        this.mVideoPlayer.addEventListener('error', (event) => {
            const error = event.detail;
            const errorEventData = {
                type: 'PLAYER_EVENT_SHAKA_DOWNLOAD_FAILED',
                source: 'streamingSessionError',
                url: error.data[0],
                code: error.code,
                message: error.data[1].message,
            };
            AnalyticsLogger.logErrorEvent(errorEventData);
            Diagnostics.error(ShakaPlayerImpl.TAG, 'PlayerEvent: error', error.detail);
            Log.e(ShakaPlayerImpl.TAG, 'PlayerEvent: error');
            Log.e(ShakaPlayerImpl.TAG, 'Error code', error.code, 'object', error);
            this.dispatch(handleVideoPlayerError(this.mVideoPlayer));
        });

        this.mVideoPlayer.addEventListener('buffering', (event) => {
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: buffering');
            if (event.buffering === true) {
                QoEAppLogger.updateHealthStatsMetricsByEvent(event);
            } else {
                // consider this as playing since buffering property is false
                QoEAppLogger.updateHealthStatsMetricsByEvent({
                    type: 'play',
                    timeStamp: event.timeStamp,
                });
            }
        });

        this.mVideoPlayer.addEventListener('emsg', () => {
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: emsg');
        });

        this.mVideoPlayer.addEventListener('unloading', () => {
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: unloading');
        });

        this.mVideoPlayer.addEventListener('streaming', () => {
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: streaming');
        });

        this.mVideoPlayer.addEventListener('onstatechange', () => {
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: onstatechange');
        });

        this.mVideoPlayer.addEventListener('onstateidle', () => {
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: onstateidle');
            const activeVariant = this.getCurrentAudioTrack();
            if (activeVariant) {
                const variantDetails = {
                    audiocodec: activeVariant.audioCodec,
                    videoCodec: activeVariant.videoCodec,
                    mimeType: activeVariant.mimeType,
                    language: activeVariant.language,
                };
                Log.i(
                    ShakaPlayerImpl.TAG,
                    'Active variant details: ',
                    JSON.stringify(variantDetails),
                );
            }
        });

        this.mVideoPlayer.addEventListener('ratechange', (event) => {
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: ratechange');
            QoEAppLogger.updateHealthStatsMetricsByEvent(event);
        });

        this.mVideoPlayer.addEventListener('metadata', () => {
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: metadata found');
        });

        this.mVideoPlayer.addEventListener('manifestparsed', (event) => {
            Log.i(ShakaPlayerImpl.TAG, 'PlayerEvent: manifestparsed');
            QoEAppLogger.updateHealthStatsMetricsByEvent({
                type: 'buffering',
                timeStamp: event.timeStamp,
            });
        });
    };
}

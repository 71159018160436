import { AnyAction } from 'redux';
import {
    SHAKA_PLAYER_START_PLAYBACK,
    SHAKA_PLAYER_RESTART_PLAYBACK,
    SHAKA_PLAYER_TOGGLE_CLOSED_CAPTION,
    SHAKA_PLAYER_RESET_RETRY,
} from './ShakaPlayer.types';

export const shakaPlayerStartPlaybackAction = (playUrl: string): AnyAction => ({
    type: SHAKA_PLAYER_START_PLAYBACK,
    payload: {
        playUrl,
    },
});

export const shakaPlayerRestartPlaybackAction = (playUrl: string, retry: number): AnyAction => ({
    type: SHAKA_PLAYER_RESTART_PLAYBACK,
    payload: {
        playUrl,
        retry,
    },
});

export const shakaPlayerSetClosedCaptionAction = (cc: boolean): AnyAction => ({
    type: SHAKA_PLAYER_TOGGLE_CLOSED_CAPTION,
    payload: {
        cc,
    },
});

export const shakaPlayerResetRetryAction = (): AnyAction => ({
    type: SHAKA_PLAYER_RESET_RETRY,
});

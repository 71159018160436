import { AnyAction } from 'redux';
import { OverlayNavigationTypes } from '../../../core/infrastructure/navigation/OverlayNavigationTypes';
import { setOverlayNavigationType } from '../../appl/Appl.reducers';
import { toggleClosedCaption } from '../../shakaplayer/ShakaPlayer.reducers';
import { ShakaPlayerImpl } from '../../../views/ShakaPlayer/impl/ShakaPlayerImpl';
import { setOverlayHighlightVerticalPositionAction } from '../overlayhighlight/overlayhighlight.actions';
import {
    hideSubtitleAndCCLanguageOverlayAction,
    showSubtitleAndCCLanguageOverlayAction,
} from './subtitleandcclanguageoverlay.actions';
import {
    HIDE_SUBTITLE_AND_CC_LANGUAGE_OVERLAY,
    SHOW_SUBTITLE_AND_CC_LANGUAGE_OVERLAY,
} from './subtitleandcclanguageoverlay.types';

const initialState: {
    isSubtitleAndCCLanguageOverlayActive: boolean;
    subtitleLangaugeList: [];
} = {
    isSubtitleAndCCLanguageOverlayActive: false,
    subtitleLangaugeList: [],
};

const subtitleAndCCLanguageOverlayReducer = (
    state = initialState,
    { type, payload } = <AnyAction>{},
) => {
    switch (type) {
        case SHOW_SUBTITLE_AND_CC_LANGUAGE_OVERLAY:
        case HIDE_SUBTITLE_AND_CC_LANGUAGE_OVERLAY:
            return {
                ...state,
                isSubtitleAndCCLanguageOverlayActive: payload.isSubtitleAndCCLanguageOverlayActive,
                subtitleLangaugeList: payload.subtitleLangaugeList,
            };

        default:
            return state;
    }
};

export const showSubtitleAndCCLanguageOverlay = () => (dispatch: Function) => {
    dispatch(
        showSubtitleAndCCLanguageOverlayAction(ShakaPlayerImpl.getInstance().getTextTrackModel()),
    );
    dispatch(setOverlayHighlightVerticalPositionAction(0));
    dispatch(setOverlayNavigationType(OverlayNavigationTypes.SUBTITLE_AND_CC_LANGUAGE_OVERLAY));
};

export const hideSubtitleAndCCLanguageOverlay = () => (dispatch: Function) => {
    dispatch(hideSubtitleAndCCLanguageOverlayAction());
};

export const onLanguageSelect =
    (selectedIndex: number) => (dispatch: Function, getState: Function) => {
        const { cc } = getState().shakaPlayer;
        if (!cc) dispatch(toggleClosedCaption());
        ShakaPlayerImpl.getInstance().setCurrentTextTrack(selectedIndex);
        dispatch(hideSubtitleAndCCLanguageOverlay());
    };

export default subtitleAndCCLanguageOverlayReducer;

import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { SelectionDialog } from '../../../SelectionDialog';
import { RootState } from '../../../../store/root.reducers';
import { ActionsStrip } from '../../ActionStrip';
import { ShakaPlayerImpl } from '../../../ShakaPlayer/impl/ShakaPlayerImpl';
import { Locale, LocaleKeys } from '../../../../core/locale';
import {
    CHANGE_AUDIO_TRACK,
    TURN_SUBTITLES_CC_ON,
    CHANGE_SUBTITLES_CC_LANGUAGE,
} from '../../../../store/infobanner/ActionsStripNavigation.types';
import './PlaybackControls.scss';

const PlaybackControls: FunctionComponent = (): JSX.Element => {
    const { isPlaybackControlsActive } = useSelector((state: RootState) => state.infoBanner);
    const { isAudioTrackOverlayActive, audioTrackList } = useSelector(
        (state: RootState) => state.audioTrackOverlay,
    );
    const { isSubtitleAndCCLanguageOverlayActive, subtitleLangaugeList } = useSelector(
        (state: RootState) => state.subtitleAndCCLanguageOverlay,
    );
    const shakaPlayer = ShakaPlayerImpl.getInstance();
    const actionItems = [
        {
            id: CHANGE_AUDIO_TRACK,
            name: Locale.getString(LocaleKeys.CHANGE_AUDIO_TRACK),
            visible: true,
        },
        {
            id: TURN_SUBTITLES_CC_ON,
            name: Locale.getString(LocaleKeys.TURN_SUBTITLES_CC_ON),
            visible: true,
        },
        {
            id: CHANGE_SUBTITLES_CC_LANGUAGE,
            name: Locale.getString(LocaleKeys.CHANGE_SUBTITLES_CC_LANGUAGE),
            visible: shakaPlayer.isTextTrackChangeSupported(),
        },
    ];

    return (
        <React.Fragment>
            {isPlaybackControlsActive ? (
                <span className="play-back-controls">
                    {Locale.getString(LocaleKeys.PLAYBACK_CONTROLS_UNSUPPORTED)}
                </span>
            ) : null}
            <ActionsStrip actionItems={actionItems} />
            {isAudioTrackOverlayActive && (
                <SelectionDialog
                    title={Locale.getString(LocaleKeys.AUDIO_TRACKS_OVERLAY_TITLE)}
                    optionsList={audioTrackList}
                />
            )}
            {isSubtitleAndCCLanguageOverlayActive && (
                <SelectionDialog
                    title={Locale.getString(LocaleKeys.SUBTITLE_CC_LANGUAGES_OVERLAY_TITLE)}
                    optionsList={subtitleLangaugeList}
                />
            )}
        </React.Fragment>
    );
};

export default PlaybackControls;

export abstract class Constants {
    static readonly RETRY_LIMIT: number = 3;

    static readonly TIMEOUT_VALUE: number = 3000;

    // trick play bar constants
    static readonly TPB_DISPLAY_TIME = 2000; // time in ms

    // trick play bar - progress bar constants
    static readonly PROGRESS_BAR_LENGTH = 1527;

    static readonly ROUNDOFF_TIME_DIFFERENECE = 45 * 60000; // converting minutes into miliseconds

    static readonly ROUNDOFF_TIME_MINUTES = 30;

    static readonly CONTENT_DURATION_IN_HOUR = 1; // time in hours

    static readonly HOUR_TO_MILISEC_CONVERTER = 3600000;

    static readonly CLOCK_REFRESH_TIME = 1000; // time in mili sec

    static readonly TICK_MARK_LEFT_MARGIN = 25;

    static readonly MINS_TO_MILISEC_CONVERTER = 60000;

    static readonly HOUR_TO_MIN_CONVERTER = 60;

    // info banner timers
    static readonly STANDARD_INFO_BANNER_DISPLAY_TIME = 10000; // 10 sec

    static readonly FULL_INFO_BANNER_DISPLAY_TIME = 10000; // 10 sec
}

/**
 * Timer constants
 */
// 3 hours 45 minutes: (3 * 60 + 45) * 60 * 1000;
export const STILL_THERE_THRESHOLD_TIME = (3 * 60 + 45) * 60 * 1000;
// 15 minutes in seconds: 15 * 60 * 1000;
export const EXIT_PLAYER_THRESHOLD_TIME = 15 * 60 * 1000;

// Parental Control Constants
export const PARENTAL_CONTROL_PIN_MAX_LENGTH = 4;
export const PARENTAL_CONTROL_PIN_MAX_RETRY = 2;

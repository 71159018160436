import React, { FunctionComponent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setNavigationHandler } from '../../store/appl/Appl.reducers';
import { Locale, LocaleKeys } from '../../core/locale';
import './MessageOverlay.scss';

interface MessageOverlayDialog {
    title: string;
    code: string;
    description: string;
}

interface DialogProps {
    dialog: MessageOverlayDialog;
    id: string;
    isMessageOverlayActive: boolean;
    handler: any;
}

const MessageOverlay: FunctionComponent<DialogProps> = (props): JSX.Element => {
    const dispatch = useDispatch();
    const { dialog, id, isMessageOverlayActive, handler } = props;
    const [overlayStatus, setOverlayStatus] = useState(false);

    useEffect(() => {
        if (isMessageOverlayActive && !overlayStatus) {
            setOverlayStatus(true);
        }
        if (isMessageOverlayActive) dispatch(setNavigationHandler(handler));
    }, [isMessageOverlayActive]);

    return (
        <div id={id} className={`message-overlay ${!overlayStatus ? 'hidden' : ''}`}>
            <div className={`dimmer ${!isMessageOverlayActive ? 'hidden' : ''}`}></div>
            <div className="dialog">
                <div
                    className={`normal-dialog ${
                        isMessageOverlayActive ? 'show-dialog' : 'hide-dialog'
                    }`}
                >
                    <div className="header">
                        <span className="title">{dialog.title ? dialog.title : ''}</span>
                        <span className="sub-text">{dialog.code ? dialog.code : ''}</span>
                    </div>
                    <div className="divider"></div>
                    <span className="overlay-description">
                        {dialog.description ? dialog.description : ''}
                    </span>
                    <div className="highlight">
                        <div className="rectangle">
                            <span className="text">{Locale.getString(LocaleKeys.OK)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

MessageOverlay.propTypes = {
    dialog: PropTypes.shape({
        code: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    isMessageOverlayActive: PropTypes.bool.isRequired,
    handler: PropTypes.any,
};

export default MessageOverlay;

import { ChannelData } from '../../entities/stationdata/ChannelData';
import { IStationDataRepository } from '../../entities/stationdata/IStationDataRepository';

export class StationDataImpl implements IStationDataRepository {
    stationDataRepo: IStationDataRepository;

    constructor(stationData: IStationDataRepository) {
        this.stationDataRepo = stationData;
    }

    async getStationData(): Promise<ChannelData[]> {
        return this.stationDataRepo.getStationData();
    }
}

import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/root.reducers';
import './Welcome.scss';

const Welcome: FunctionComponent = (): JSX.Element => {
    const appl = useSelector((state: RootState) => state.appl);
    const { isWelcomeOn } = appl;

    return (
        <div
            id="inital-welcome"
            className={`inital-welcome-container welcome ${
                isWelcomeOn ? 'welcome-active' : 'hidden'
            }`}
        ></div>
    );
};

export default Welcome;

export class NavigationHandlers {
    static SilentHandler = 'SilentHandler';

    static MessageOverlayHandler = 'MessageOverlayHandler';

    static CantPlayHandler = 'CantPlayHandler';

    static StillThereHandler = 'StillThereHandler';

    static LostInternetConnectionHandler = 'LostInternetConnectionHandler';

    static ApplHandler = 'ApplHandler';

    static PinDialogHandler = 'PinDialogHandler';

    static ActionStripHandler = 'ActionStripHandler';

    static OverlayNavigationHandler = 'OverlayNavigationHandler';
}

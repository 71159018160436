import React, { FunctionComponent } from 'react';
import { PlaybackControls } from './PlaybackControls';
import { ProgressBar } from './ProgressBar';
import './TrickPlayBar.scss';

const TrickPlayBar: FunctionComponent = (): JSX.Element => {
    return (
        <div className="trick-play-bar">
            <img className="tpb-bottom-background" />
            <ProgressBar />
            <PlaybackControls />
        </div>
    );
};

export default TrickPlayBar;

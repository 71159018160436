import { Constants } from '../constants';
import { Log } from '../logging/Log';

export class TrackUtils {
    TAG: string = 'TrackUtils';

    private static mTrackUtil: TrackUtils;

    private displayText: string = '';

    static getInstance(): TrackUtils {
        if (!TrackUtils.mTrackUtil) {
            TrackUtils.mTrackUtil = new TrackUtils();
        }
        return TrackUtils.mTrackUtil;
    }

    // language code from ISO 639-2 Code | ISO 639-1 Code
    private languageMapping = new Map<string, string>([
        ['en', 'English'],
        ['eng', 'English'],
        ['es', 'Spanish'],
        ['spa', 'Spanish'],
        ['fr', 'French'],
        ['fra', 'French'],
        ['fre', 'French'],
        ['de', 'German'],
        ['deu', 'German'],
        ['it', 'Italian'],
        ['ita', 'Italian'],
        ['ja', 'Japanese'],
        ['jpn', 'Japanese'],
        ['ko', 'Korean'],
        ['kor', 'Korean'],
        ['zh', 'Chinese'],
        ['zho', 'Chinese'],
        ['mi', 'Maori'],
        ['mri', 'Maori'],
        ['hi', 'Hindi'],
        ['hin', 'Hindi'],
        ['cy', 'Welsh'],
        ['cym', 'Welsh'],
        ['eu', 'Basque'],
        ['eus', 'Basque'],
        ['ca', 'Catalan'],
        ['cat', 'Catalan'],
        ['gl', 'Galician'],
        ['glg', 'Galician'],
        ['pt', 'Portuguese'],
        ['por', 'Portuguese'],
        ['sv', 'Swedish'],
        ['swe', 'Swedish'],
        ['ar', 'Arabic'],
        ['ara', 'Arabic'],
        ['ru', 'Russian'],
        ['rus', 'Russian'],
        ['nn', 'Norwegian'],
        ['nor', 'Norwegian'],
        ['nob', 'Norwegian'],
        ['fi', 'Finnish'],
        ['fin', 'Finnish'],
        ['da', 'Danish'],
        ['dan', 'Danish'],
        ['gd', 'Gaelic'],
        ['gla', 'Gaelic'],
        ['tr', 'Turkish'],
        ['tur', 'Turkish'],
        ['pl', 'Polish'],
        ['pol', 'Polish'],
        ['fa', 'Persian'],
        ['fas', 'Persian'],
        ['ur', 'Urdu'],
        ['urd', 'Urdu'],
        ['et', 'Estonian'],
        ['est', 'Estonian'],
        ['--', 'Multiple'],
        ['mul', 'Multiple'],
    ]);

    /**
     * to map Language code to Language name.
     * @param code Language code
     * @param defaultValue Default display string for
     *                     un-mapped language codes
     * @returns Name of the Language for a given Key
     */
    getLanguageByCode(code: string, defaultValue: string): string {
        return this.languageMapping.has(code) ? this.languageMapping.get(code)! : defaultValue;
    }

    /**
     * to map codec value to string.
     * @param codecVal codec value defined in manifest track
     * @returns text to Display codec
     */
    getAudioCodec(codecVal: string): string {
        if (!codecVal || !codecVal.length) {
            return this.displayText;
        }
        /*
         * Below values are based on best known manifest
         * metadata and may need changes if new possible
         * values are encountered in future
         */
        const codec = codecVal.toLowerCase();

        if (codec.includes('ac-3') || codec.includes('ac3')) {
            this.displayText = Constants.AUDIO_FORMAT_DOLBY_DIGITAL;
        } else if (
            codec.includes('mp4a') ||
            codec.includes('audio/mpeg') ||
            codec.includes('aac')
        ) {
            this.displayText = Constants.AUDIO_FORMAT_AAC;
        } else if (codec === 'mpeg1' || codec === 'mpeg-1' || codec === 'audio/mpeg-l1') {
            this.displayText = Constants.AUDIO_FORMAT_MPEG1;
        } else if (codec === 'mpeg2' || codec === 'mpeg-2' || codec === 'audio/mpeg-l2') {
            this.displayText = Constants.AUDIO_FORMAT_MPEG2;
        } else if (
            codec === 'eac3' ||
            codec === 'eac-3' ||
            codec === 'ec-3' ||
            codec === 'audio/eac3'
        ) {
            this.displayText = Constants.AUDIO_FORMAT_DOLBY_DIGITAL;
        }

        return this.displayText;
    }

    /**
     * to get the display Text of individual track.
     * @param track Audio track
     * @param nameMap map of audioId and Language string
     *
     * @returns display Text of audio track
     */
    private getDisplayTextForAudio(track: any, nameMap: Map<number, string>): string {
        let displayText = nameMap.get(track.audioId)!;
        // Add if track is Audio description
        if (track.kind === Constants.AUDIO_DESCRIPTION_CONST) {
            displayText += Constants.SPACED_DASH + Constants.AUDIO_DESCRIPTION;
        }
        // Add if codec details available
        const codec = this.getAudioCodec(
            track.audioCodec === null ? track.audioMimeType : track.audioCodec,
        );
        if (codec.length > 0) {
            displayText += Constants.SPACED_DASH + codec;
        }
        return displayText;
    }

    /**
     * to map audioId with Language Map.
     * @param tracks array of audio tracks
     * @returns array of audio track processed data
     */
    getAudioNameMap(tracks: any[]): Map<number, string> {
        const langMap: Map<string, any[]> = new Map();

        tracks.forEach((element) => {
            const group = langMap.get(element.language);
            if (group === undefined) {
                langMap.set(element.language, [element]);
            } else {
                group.push(element);
            }
        });

        const nameMap: Map<number, string> = new Map();
        langMap.forEach((value, key) => {
            const trackArr = value;
            let langName = this.getLanguageByCode(key, Constants.AUDIO_LANGUAGE_UNKNOWN);

            if (trackArr.length === 1) {
                nameMap.set(trackArr[0].audioId, langName);
            } else {
                langName += Constants.SPACE;
                for (let i = 0; i < trackArr.length; i += 1) {
                    nameMap.set(trackArr[i].audioId, langName + (i + 1).toString());
                }
            }
        });
        return nameMap;
    }

    /**
     * to get the map of audioId and display text of track.
     * @param tracks array of audio tracks
     * @returns array of audio track processed data
     */
    getAudioTrackMap(tracks: any[]): Map<number, any> {
        const audioTrackMap: Map<number, string> = new Map<number, any>();

        const nameMap = this.getAudioNameMap(tracks);

        tracks.forEach((audioTrack) => {
            audioTrackMap.set(audioTrack.audioId, this.getDisplayTextForAudio(audioTrack, nameMap));
        });
        Log.i(this.TAG, `getAudioTrackMap :`, audioTrackMap);
        return audioTrackMap;
    }
}

import { AnyAction } from 'redux';
import { MetaData } from '../../core/entities/metadata/MetaData';
import {
    META_DATA_FETCH_REQUEST,
    META_DATA_FETCH_REQUEST_RETRY,
    META_DATA_STORE,
    META_DATA_FETCH_REQUEST_FAILURE,
} from './MetaData.types';

export const fetchMetaDataAction = (): AnyAction => ({
    type: META_DATA_FETCH_REQUEST,
});

export const retryFetchMetaDataAction = (retry: number): AnyAction => ({
    type: META_DATA_FETCH_REQUEST_RETRY,
    payload: {
        retry,
    },
});

export const storeMetaDataAction = (metaData: MetaData[]): AnyAction => ({
    type: META_DATA_STORE,
    payload: {
        metaData,
    },
});

export const failureMetaDataAction = (): AnyAction => ({
    type: META_DATA_FETCH_REQUEST_FAILURE,
    payload: {
        retry: 0,
    },
});

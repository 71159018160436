export const DEFAULT_AD_TRACKING_ENABLED = false;
export const DEFAULT_APP_NAME = 'TiVoPlus-Oyster';
export const DEFAULT_APP_VERSION = '0.1.0';
export const DEFAULT_CONNECTION = 'Unknown';
export const DEFAULT_DEVICE_ID = '00:11:22:33:44:55';
export const DEFAULT_DEVICE_TYPE = 'Smart-TV';
export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_USER_ID = '00000000-0000-0000-0000-000000000000';
export const DEFAULT_VIDEO_PLAYER = 'ShakaPlayer';
export const DEFAULT_VIDEO_SOURCE = 'IPTV';
// TODO: handle dev and prod apiKeys
// TODO: hide apiKeys using encryption method.
export const DEV_SEGMENT_APIKEY = 'Ufy21EMlfdWPhW2YHxoEOThXcbeH7Cy1'; // Dev Segment API key for TiVo+

// TODO: ENABLE_DIAGNOTICS_LOGGING to be false by default. Value to be updated dynamically
export const ENABLE_DIAGNOTICS_LOGGING = true;

export const PLAYBACK_PARAMS = {
    APS_ENDPOINT: 'apsEndpoint',
    COUNTRY: 'country',
    DLID: 'dlid',
    DOMAIN_TOKEN: 'domainToken',
    HSN: 'hsn',
    LOCALE: 'locale',
    MODEL_NAME: 'modelName',
    MSO_NAME: 'msoName',
    MSO_PARTNER_ID: 'msoPartnerId',
    OS_VERSION: 'osVersion',
    PIN_STATUS: 'pinStatus',
    STATION_ID: 'stationId',
    STREAM_URL: 'streamUrl',
    TSN: 'tsn',
};

// sage endpoint
export const SAGE_BASE_URL = 'https://sage-hec.oi.tivo.com:8088/services/collector/event';

export const SAGE_LOGGING_VERSION = '2.0.1';

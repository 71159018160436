import { AnyAction } from 'redux';
import { Constants } from '../../constants';
import { SlsServiceEndpoint } from '../../core/entities/sls/SlsServiceEndpoint';
import { SlsServiceEndpointSearchRepositoryImpl } from '../../core/infrastructure/sls/SlsServiceEndpointSearchRepositoryImpl';
import { Log } from '../../core/logging/Log';
import { SlsServiceEndpointSearchImpl } from '../../core/usecases/sls/SlsServiceEndpointSearchImpl';
import {
    retryFetchSlsServiceEndpointSearchAction,
    failureSlsServiceEndpointSearchAction,
    storeSlsServiceEndpointSearchAction,
} from './SlsServiceEndpointSearch.actions';
import {
    SLS_ENDPOINT_FETCH_REQUEST,
    SLS_ENDPOINT_STORE,
    SLS_ENDPOINT_FETCH_REQUEST_FAILURE,
    SLS_ENDPOINT_FETCH_REQUEST_RETRY,
} from './SlsServiceEndpointSearch.types';

const TAG = 'slsServiceEndpointSearchReducer';

const initialState: {
    slsServiceEndpoints: SlsServiceEndpoint[];
    retry: number;
} = { slsServiceEndpoints: [], retry: 0 };

const slsServiceEndpointSearchReducer = (
    state = initialState,
    { type, payload } = <AnyAction>{},
) => {
    switch (type) {
        case SLS_ENDPOINT_FETCH_REQUEST:
            return {
                ...state,
            };

        case SLS_ENDPOINT_FETCH_REQUEST_FAILURE:
            return {
                ...state,
                retry: payload.retry || 0,
            };

        case SLS_ENDPOINT_STORE:
            return {
                ...state,
                slsServiceEndpoints: payload.slsServiceEndpoints,
            };
        case SLS_ENDPOINT_FETCH_REQUEST_RETRY:
            return {
                ...state,
                retry: payload.retry,
            };
        default:
            return state;
    }
};

export const fetchSlsServiceEndpointSearch = () => (dispatch: Function) => {
    try {
        const slsRepsositoryImpl = new SlsServiceEndpointSearchRepositoryImpl();
        const slsEndPointSearchRequestServiceImpl = new SlsServiceEndpointSearchImpl(
            slsRepsositoryImpl,
        );
        slsEndPointSearchRequestServiceImpl
            .getSlsServiceEndpoints()
            .then((slsServiceEndpoints: SlsServiceEndpoint[]) => {
                dispatch(storeSlsServiceEndpointSearchAction(slsServiceEndpoints));
            })
            .catch(() => {
                dispatch(retryFetchSlsServiceEndpointSearch());
            });
    } catch (error) {
        dispatch(retryFetchSlsServiceEndpointSearch());
    }
};

export const retryFetchSlsServiceEndpointSearch =
    () => (dispatch: Function, getState: Function) => {
        const { slsServiceEndpointSearch } = getState();
        const { retry } = slsServiceEndpointSearch;

        if (retry < Constants.RETRY_LIMIT) {
            Log.w(TAG, 'SLS-Service,retryCount ->', retry);
            setTimeout(() => {
                dispatch(retryFetchSlsServiceEndpointSearchAction(retry + 1));
                dispatch(fetchSlsServiceEndpointSearch());
            }, Constants.TIMEOUT_VALUE);
        } else {
            dispatch(failureSlsServiceEndpointSearch());
        }
    };

export const failureSlsServiceEndpointSearch = () => (dispatch: Function) => {
    dispatch(failureSlsServiceEndpointSearchAction());
};

export default slsServiceEndpointSearchReducer;

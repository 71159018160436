export const TPB_SET_START_END_TIME = 'TPB_SET_START_END_TIME';
export const TPB_SET_CURRENT_TIME = 'TPB_SET_CURRENT_TIME';
export const TPB_SET_CURRENT_POSITION = 'TPB_SET_CURRENT_POSITION';
export const TPB_SHOW_TRICK_PLAY_BAR = 'TPB_SHOW_TRICK_PLAY_BAR';
export const TPB_HIDE_TRICK_PLAY_BAR = 'TPB_HIDE_TRICK_PLAY_BAR';
export const TPB_UPDATE_TRICK_PLAY_BAR_TIMER = 'TPB_UPDATE_TRICK_PLAY_BAR_TIMER';
export const TPB_UPDATE_PLAYBACK_CONTROLS_TIMER = 'TPB_UPDATE_PLAYBACK_CONTROLS_TIMER';
export const TPB_SET_PLAYBACK_CONTROLS = 'TPB_SET_PLAYBACK_CONTROLS';
// Standard InfoBanner
export const SIB_UPDATE_STANDARD_INFO_TIMER = 'SIB_UPDATE_STANDARD_INFO_TIMER';
export const SIB_SHOW_STANDARD_INFO = 'SIB_SHOW_STANDARD_INFO';
export const SIB_HIDE_STANDARD_INFO = 'SIB_HIDE_STANDARD_INFO';
export const SIB_SET_DATA_STANDARD_INFO = 'SIB_SET_STANDARD_INFO';
// Full Info
export const FIB_HIDE_FULL_INFO = 'FIB_HIDE_FULL_INFO';
export const FIB_SHOW_FULL_INFO = 'FIB_SHOW_FULL_INFO';
export const FIB_UPDATE_FULL_INFO_TIMEOUT_ID = 'FIB_UPDATE_FULL_INFO_TIMEOUT_ID';
// Action Strip
export const SET_ACTIONS_STRIP_HIGHLIGHT_INDEX = 'SET_ACTIONS_STRIP_HIGHLIGHT_INDEX';
export const SET_ACTIONS_STRIP_AVAILABLE_ACTION_ITEM = 'SET_ACTIONS_STRIP_AVAILABLE_ACTION_ITEM';
export const SET_ACTIONS_STRIP_ACTION_ITEMS_COUNT = 'SET_ACTIONS_STRIP_ACTION_ITEMS_COUNT';
export const SET_IS_OVERLAY_ACTIVE = 'SET_IS_OVERLAY_ACTIVE';

import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Diagnostics } from '@tivocorp/tivo-webapp-loggers';
import { useSelector, useDispatch } from 'react-redux';
import { AnalyticsLogger, QoEAppLogger } from '../../core/logging';
import { RootState } from '../../store/root.reducers';
import {
    resetPlaybackRetry,
    handleVideoPlayerError,
} from '../../store/shakaplayer/ShakaPlayer.reducers';
import { Log } from '../../core/logging/Log';
import { ShakaPlayerImpl } from './impl/ShakaPlayerImpl';
import './ShakaPlayer.scss';

const shaka = require('shaka-player/shaka-player.compiled');

shaka.polyfill.installAll();

const ShakaPlayer: FunctionComponent = (): JSX.Element => {
    const TAG = 'ShakaPlayer';
    const { shakaPlayer } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { playUrl, cc, retry } = shakaPlayer;
    const uiContainerRef = useRef(null);
    const videoRef = useRef(null);
    const isVideoPlayerActive = useRef(false);
    const videoPlayer = ShakaPlayerImpl.getInstance().mVideoPlayer;

    useEffect(() => {
        if (videoPlayer) {
            const playerConfig = {
                playerEl: videoRef.current,
                playerRootEl: uiContainerRef.current,
            };
            ShakaPlayerImpl.getInstance().configure(playerConfig);
        }
    }, [videoPlayer]);

    useEffect(() => {
        if (videoPlayer) {
            videoPlayer.setTextTrackVisibility(cc);
            const activeTextTrack = videoPlayer
                .getTextTracks()
                .filter((textTrack: { active: any }) => textTrack.active)[0];
            if (activeTextTrack) {
                const textTrackDetails = {
                    language: activeTextTrack.language,
                    mimeType: activeTextTrack.mimeType,
                };
                Log.i(TAG, 'Text track details: ', JSON.stringify(textTrackDetails));
            }
        }
    }, [videoPlayer, cc]);

    useEffect(() => {
        if (videoPlayer && isVideoPlayerActive.current === false) {
            videoPlayer
                .load(playUrl)
                .then(() => {
                    // This runs if the asynchronous load is successful.
                    // intialize and reset QoE collected values
                    QoEAppLogger.logWatchContentStartEvent();
                    QoEAppLogger.logStreamingStartEvent();
                    QoEAppLogger.logSessionCreatedEvent();
                    QoEAppLogger.logVideoPlayerOpenStreamEvent();
                    Log.i(TAG, 'The video has now been loaded!');
                    videoPlayer.getMediaElement().play();
                    dispatch(resetPlaybackRetry());
                    isVideoPlayerActive.current = true;
                    // use this flag for firstFrame render time to log fristFrameEvent
                    videoPlayer.isFirstFrameTriggered = false;
                    clearInterval(videoPlayer.timer);
                })
                .catch((err) => {
                    onError(err);
                    // on load we will log qoe startEvents so incase of error we should log
                    // QoE end events.
                    QoEAppLogger.setEndReasonAndLogEvent(
                        QoEAppLogger.PlaybackEndReasons.PLAYER_ERROR,
                    );
                    const errorMessage = 'Failed to start the playback, loading is failed';
                    AnalyticsLogger.logErrorEvent({
                        type: 'PLAYBACK_LOAD_FAILED',
                        source: 'streamingSessionError',
                        url: playUrl,
                        code: '',
                        message: errorMessage,
                    });
                    Diagnostics.error(TAG, errorMessage, err);
                    dispatch(handleVideoPlayerError(videoPlayer));
                });
        }
    }, [videoPlayer, playUrl, retry]);

    const onError = (error) => {
        // Log the error.
        Log.d(TAG, 'Error code', error.code, 'object', error);
    };
    return (
        <div className="shaka-player-container" ref={uiContainerRef}>
            <video className="shaka-player" ref={videoRef} autoPlay></video>
        </div>
    );
};

export default ShakaPlayer;

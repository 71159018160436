import { Diagnostics, Payload, SegmentTransport, VU } from '@tivocorp/tivo-webapp-loggers';
import { DEV_SEGMENT_APIKEY } from '../../config';
import { AppUtil } from '../utils/AppUtil';
import { Storage } from '../storage/Storage';
import { IAnalyticsContent } from './IAnalyticsContent';
import { ChannelData } from '../entities/stationdata/ChannelData';
import { MetaData } from '../entities/metadata/MetaData';
import { Log } from './Log';

export class AnalyticsLogger {
    private static TAG: string = 'AnalyticsLogger';

    private static userId: string;

    private static storage = Storage.getInstance();

    private static isWatchStarted: boolean = false;

    private static isPlaybackErrorState: boolean = false;

    private static content: IAnalyticsContent = {
        channelName: '',
        contentType: 'linearChannel',
        duration: '',
        episodeNumber: '',
        episodeTitle: '',
        genre: '',
        seasonNumber: '',
        startTime: '',
        stationId: '',
        viewingSessionId: '',
        year: '',
    };

    static createUserId(deviceTSN: string) {
        AnalyticsLogger.userId = AppUtil.getUuid(deviceTSN);
    }

    static setSegmentGlobalParams(globalPayload: Payload) {
        VU.noConsole();
        const segmentTransport = new SegmentTransport({ key: DEV_SEGMENT_APIKEY });
        VU.getInstance().setTransport(segmentTransport);
        VU.setGlobals(globalPayload);
    }

    static updateAnalyticsContentPayload(channelData: ChannelData, metaData: MetaData) {
        AnalyticsLogger.content.channelName = channelData.channelName || '';
        AnalyticsLogger.content.episodeTitle = metaData.episodeTitle || metaData.title || '';
        AnalyticsLogger.content.duration = metaData.duration || '';
        AnalyticsLogger.content.episodeNumber = metaData.episodeNumber || '';
        AnalyticsLogger.content.genre = metaData.genres.toString() || '';
        AnalyticsLogger.content.seasonNumber = metaData.seasonNumber || '';
        AnalyticsLogger.content.startTime = <string>(<unknown>metaData.startTimestamp) || '';
        AnalyticsLogger.content.stationId = channelData.stationId || '';
        AnalyticsLogger.content.year = metaData.releaseYear || '';
    }

    // ************************** Vu logging events ***************************
    // identify
    // appEntry
    // appExit
    // watchStartedEvent
    // watchStoppeEvent
    // streamingRecoveryEvent
    // ************************************************************************

    static logIdentifyCall() {
        const userBindingPayload: Payload = new Payload();
        userBindingPayload.add('userId', AnalyticsLogger.userId);
        VU.identify(userBindingPayload);
    }

    static logAppEntryEvent() {
        const payload: Payload = new Payload();
        payload.add('entryType', 'deepLink');
        const storageUserId = AnalyticsLogger.storage.get(Storage.Keys.USER_ID) || [];
        if (storageUserId.includes(AnalyticsLogger.userId)) payload.add('userType', 'returnUser');
        else {
            payload.add('userType', 'newUser');
            storageUserId.push(AnalyticsLogger.userId);
            AnalyticsLogger.storage.store(Storage.Keys.USER_ID, storageUserId);
        }
        VU.appEntryEvent(payload);
    }

    static logAppExitEvent() {
        const payload: Payload = new Payload();
        payload.add('action', 'USER_EXITED');
        VU.appExitEvent(payload);
    }

    static logWatchStartedEvent() {
        if (AnalyticsLogger.isWatchStarted) {
            // log stopped event if previous event was start.
            AnalyticsLogger.logWatchStoppedEvent('unknown');
        }
        // set new sessionId for every watchStart event.
        AnalyticsLogger.content.viewingSessionId = AppUtil.getUuid();
        const payload: Payload = new Payload(AnalyticsLogger.content);
        VU.watchStartedEvent(payload);
        AnalyticsLogger.isWatchStarted = true;
    }

    static logWatchStoppedEvent(reason: string) {
        if (!AnalyticsLogger.isWatchStarted) {
            Log.w(AnalyticsLogger.TAG, 'Trying to log watchStopped event without watchStarted ...');
            Diagnostics.warn(
                AnalyticsLogger.TAG,
                'Ignored. - Trying to log watchStopped event without watchStarted ...',
            );
            return;
        }
        const payload: Payload = new Payload(AnalyticsLogger.content);
        payload.add('reason', reason);
        VU.watchStoppedEvent(payload);
        AnalyticsLogger.isWatchStarted = false;
    }

    static logStreamingRecoveryEvent(recoveryType: string) {
        // check this is called before watchStart then ignore.
        if (!this.isWatchStarted) return;
        const payload: Payload = new Payload(AnalyticsLogger.content);
        payload.add('recoveryType', recoveryType);
        VU.streamingRecovery(payload);
        // reset error state once it recovered.
        AnalyticsLogger.isPlaybackErrorState = false;
    }

    static logAppError(appError: string) {
        const payload: Payload = new Payload();
        payload.add('error', appError);
        VU.appError(payload);
    }

    static logErrorEvent(error: any) {
        // is every is alredy logged ignore duplicate events.
        if (AnalyticsLogger.isPlaybackErrorState) return;
        const payload: Payload = new Payload(AnalyticsLogger.content);
        payload.add('errorMessage', error.message || '');
        payload.add('errorSource', error.source || '');
        payload.add('errorType', error.type || '');
        payload.add('errorUrl', error.url || '');
        payload.add('errorCode', error.code || '');
        VU.errorEvent(payload);
        AnalyticsLogger.isPlaybackErrorState = true;
    }

    static logKeyEvent(key: string) {
        const payload: Payload = new Payload();
        payload.add('buttonName', key);
        payload.add('sourceType', 'playerView');
        VU.keyEvent(payload);
    }
}

export default {
    preferredTextLanguage: 'en',
    preferredAudioLanguage: 'en',
    playRangeEnd: Infinity,
    playRangeStart: 0,
    streaming: {
        autoLowLatencyMode: true,
        lowLatencyMode: true,
        inaccurateManifestTolerance: 0,
        rebufferingGoal: 0.01,
        bufferBehind: 0.01,
        bufferingGoal: 120,
        durationBackoff: 1,
        ignoreTextStreamFailures: true,
        alwaysStreamText: false,
        startAtSegmentBoundary: true,
        safeSeekOffset: 3,
        forceTransmuxTS: false,
        forceHTTPS: false,
        retryParameters: {
            backoffFactor: 2,
            baseDelay: 1000,
            fuzzFactor: 0.5,
            maxAttempts: 2,
            timeout: 0,
        },
    },
    manifest: {
        defaultPresentationDelay: 0,
        hls: {
            ignoreTextStreamFailures: true,
            ignoreImageStreamFailures: true,
        },
        retryParameters: {
            timeout: 10000,
            stallTimeout: 5000,
            connectionTimeout: 10000,
            maxAttempts: 2,
            baseDelay: 1000,
            backoffFactor: 2,
            fuzzFactor: 0.5,
        },
    },
    abr: {
        switchInterval: 6,
        bandwidthUpgradeTarget: 0.85,
        bandwidthDowngradeTarget: 0.95,
        defaultBandwidthEstimate: 230000,
        enabled: true,
    },
};

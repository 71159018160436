export const ON_KEYUP_EVENT = 'ON_KEYUP_EVENT';
export const ON_KEYUP_RIGHT = 'ON_KEYUP_RIGHT';
export const ON_KEYUP_LEFT = 'ON_KEYUP_LEFT';
export const ON_KEYUP_UP = 'ON_KEYUP_UP';
export const ON_KEYUP_DOWN = 'ON_KEYUP_DOWN';
export const ON_KEYUP_INFO = 'ON_KEYUP_INFO';
export const ON_KEYUP_TIVO = 'ON_KEYUP_TIVO';
export const ON_KEYUP_OK = 'ON_KEYUP_OK';
export const ON_KEYUP_DIGIT = 'ON_KEYUP_DIGIT';
export const ON_KEYUP_BACK = 'ON_KEYUP_BACK';

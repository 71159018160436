import { NavigationHandlers } from 'src/core/infrastructure/navigation/NavigationTypes';
import { setNavigationHandler } from 'src/store/appl/Appl.reducers';
import { showTrickPlayBar, showStandardInfoBanner } from 'src/store/infobanner/InfoBanner.reducers';
import { OverlayNavigationTypes } from '../../../core/infrastructure/navigation/OverlayNavigationTypes';
import { setIsOverlayActive } from '../../infobanner/InfoBanner.actions';
import {
    hideAudioTrackOverlay,
    onTrackSelect,
} from '../audiotrackoverlay/audiotrackoverlay.reducers';
import { setOverlayHighlightVerticalPositionAction } from '../overlayhighlight/overlayhighlight.actions';
import {
    hideSubtitleAndCCLanguageOverlay,
    onLanguageSelect,
} from '../subtitleandcclanguageoverlay/subtitleandcclanguageoverlay.reducers';

export const onKeyDownOverlay = () => (dispatch: Function, getState: Function) => {
    const { overlayHighlight } = getState();
    const { verticalPosition, verticalLimit } = overlayHighlight;
    const newVerticalPosition = verticalPosition + 1;
    if (newVerticalPosition < verticalLimit)
        dispatch(setOverlayHighlightVerticalPositionAction(newVerticalPosition));
};

export const onKeyUpOverlay = () => (dispatch: Function, getState: Function) => {
    const { overlayHighlight } = getState();
    const { verticalPosition } = overlayHighlight;
    if (verticalPosition > 0)
        dispatch(setOverlayHighlightVerticalPositionAction(verticalPosition - 1));
};

export const onKeyOkOverlay = () => (dispatch: Function, getState: Function) => {
    const { appl, overlayHighlight, infoBanner } = getState();
    const { overlayNavigationType } = appl;
    const { isTrickPlayBarActive, isStandardInfoActive } = infoBanner;
    const { verticalPosition } = overlayHighlight;
    dispatch(setNavigationHandler(NavigationHandlers.ActionStripHandler));
    switch (overlayNavigationType) {
        case OverlayNavigationTypes.AUDIO_TRACK_OVERLAY:
            dispatch(onTrackSelect(verticalPosition));
            dispatch(setIsOverlayActive(false));
            if (isTrickPlayBarActive) dispatch(showTrickPlayBar());
            if (isStandardInfoActive) dispatch(showStandardInfoBanner());
            break;

        case OverlayNavigationTypes.SUBTITLE_AND_CC_LANGUAGE_OVERLAY:
            dispatch(onLanguageSelect(verticalPosition));
            dispatch(setIsOverlayActive(false));
            break;

        default:
    }
};

export const onKeyBackOverlay = () => (dispatch: Function, getState: Function) => {
    const { appl, infoBanner } = getState();
    const { overlayNavigationType } = appl;
    const { isTrickPlayBarActive, isStandardInfoActive } = infoBanner;
    dispatch(setNavigationHandler(NavigationHandlers.ActionStripHandler));
    dispatch(setIsOverlayActive(false));
    switch (overlayNavigationType) {
        case OverlayNavigationTypes.AUDIO_TRACK_OVERLAY:
            dispatch(hideAudioTrackOverlay());
            if (isTrickPlayBarActive) dispatch(showTrickPlayBar());
            if (isStandardInfoActive) dispatch(showStandardInfoBanner());
            break;

        case OverlayNavigationTypes.SUBTITLE_AND_CC_LANGUAGE_OVERLAY:
            dispatch(hideSubtitleAndCCLanguageOverlay());
            if (isTrickPlayBarActive) dispatch(showTrickPlayBar());
            if (isStandardInfoActive) dispatch(showStandardInfoBanner());
            break;

        default:
    }
};

import React, { FunctionComponent, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    setActionStripAvailableActionItems,
    setActionItemsCount,
} from '../../../store/infobanner/InfoBanner.reducers';
import { RootState } from '../../../store/root.reducers';
import { ActionsStripProps } from './ActionsStripProps';
import { ActionItem } from './ActionItem';

import './ActionStrip.scss';

const ActionsStrip: FunctionComponent<ActionsStripProps> = (props): JSX.Element => {
    const { actionItems } = props;

    const actionsStripHighlightIndex = useSelector(
        (state: RootState) => state.infoBanner.actionsStripHighlightIndex,
    );

    const availableActionItems: Array<any> = [];
    const dispatch = useDispatch();

    let actionItemsLength;

    const setActionItemsLength = () => {
        actionItemsLength = 0;
        for (let i = 0; i < actionItems.length; i += 1) {
            if (actionItems[i].visible) actionItemsLength += 1;
        }
    };

    const setAvailableActionItems = () => {
        for (let i = 0; i < actionItems.length; i += 1) {
            if (actionItems[i].visible) availableActionItems.push(actionItems[i]);
        }
    };

    useEffect(() => {
        setAvailableActionItems();
        dispatch(setActionStripAvailableActionItems(availableActionItems));
    }, [actionsStripHighlightIndex]);

    useEffect(() => {
        setActionItemsLength();
        dispatch(setActionItemsCount(actionItemsLength));
        return () => {
            dispatch(setActionItemsCount(0));
        };
    }, []);

    const actionItemsDiv = actionItems
        .filter((actionItem) => actionItem.visible)
        .map((actionItem, index) => {
            return (
                <ActionItem
                    highlighted={index === actionsStripHighlightIndex}
                    name={actionItem.name}
                    id={actionItem.id}
                    key={index}
                />
            );
        });

    return (
        <div className="actions-strip-container">
            <div className="actions-strip">{actionItemsDiv}</div>
        </div>
    );
};

ActionsStrip.propTypes = {
    actionItems: PropTypes.array.isRequired,
};

export default ActionsStrip;

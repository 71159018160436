import { AnyAction } from 'redux';
import {
    ON_KEYUP_DIGIT,
    ON_KEYUP_DOWN,
    ON_KEYUP_EVENT,
    ON_KEYUP_INFO,
    ON_KEYUP_LEFT,
    ON_KEYUP_OK,
    ON_KEYUP_RIGHT,
    ON_KEYUP_TIVO,
    ON_KEYUP_UP,
} from './inputFactory.types';

export const onKeyRightAction = (payload: any): AnyAction => ({
    type: ON_KEYUP_RIGHT,
    payload,
});

export const onKeyLeftAction = (payload: any): AnyAction => ({
    type: ON_KEYUP_LEFT,
    payload,
});

export const onKeyUpAction = (payload: any): AnyAction => ({
    type: ON_KEYUP_UP,
    payload,
});

export const onKeyDownAction = (payload: any): AnyAction => ({
    type: ON_KEYUP_DOWN,
    payload,
});

export const onKeyInfoAction = (payload: any): AnyAction => ({
    type: ON_KEYUP_INFO,
    payload,
});

export const onKeyTivoAction = (payload: any): AnyAction => ({
    type: ON_KEYUP_TIVO,
    payload,
});

export const onKeyOkAction = (payload: any): AnyAction => ({
    type: ON_KEYUP_OK,
    payload,
});

export const onKeyDigitAction = (payload: any): AnyAction => ({
    type: ON_KEYUP_DIGIT,
    payload,
});

export const onKeyEventAction = (key: string, code: string): AnyAction => ({
    type: ON_KEYUP_EVENT,
    payload: {
        key,
        code,
    },
});

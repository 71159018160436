import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { internetConnectionBack, internetConnectionLost } from '../../store/appl/Appl.reducers';

const NetworkEvents: FunctionComponent = ({ children }): JSX.Element => {
    const dispatch = useDispatch();
    useEffect(() => {
        window.addEventListener('online', () => {
            dispatch(internetConnectionBack());
        });

        window.addEventListener('offline', () => {
            dispatch(internetConnectionLost());
        });
    }, []);
    return <React.Fragment>{children}</React.Fragment>;
};

NetworkEvents.propTypes = {
    children: PropTypes.node.isRequired,
};

export default NetworkEvents;

import SparkMD5 from 'spark-md5';

export class AppUtil {
    /**
     * Method will return UUID.
     */
    static getUuid = (a: string = ''): string => {
        let userId = SparkMD5.hash(a);
        // format the user id in below format:
        // 27767c65-f950-4509-86dc-b356848472c9
        const match = userId.match(/^(\w{8})(\w{4})(\w{4})(\w{4})(\w+)$/);
        if (match) {
            userId = `${match[1]}-${match[2]}-${match[3]}-${match[4]}-${match[5]}`;
        }
        return userId;
    };

    /**
     * Method will return the Session ID.
     */
    static getSessionID = (): string => {
        return this.getUuid();
    };

    /**
     * Method  will return the user timezone.
     * Example: GMT+0530 (India Standard Time)
     */
    static getUserTimezone = () => {
        return (
            new Date().toTimeString().slice(9) ||
            Intl.DateTimeFormat().resolvedOptions().timeZone ||
            ''
        );
    };

    /**
     * Method will return a string of the standard time in following format:
     * YYYY-mm-dd HH:MM:SS timezone (timezone name)
     * Example: 2021-02-25 23:09:43 GMT+0530 (India Standard Time)
     */
    static getStandardTimestamp = () => {
        const date = new Date();
        const month = date.getMonth() + 1 < 10 ? `0+ ${date.getMonth()}+1` : `${date.getMonth()}+1`;
        return `${date.getFullYear()}-${month}-${date.getDate()} ${date.toTimeString()}`;
    };
}

import React, { FunctionComponent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/root.reducers';
import { appStart } from '../../store/appl/Appl.reducers';
import { Welcome } from '../Welcome';
import { ShakaPlayer } from '../ShakaPlayer';
import { MessageOverlay } from '../MessageOverlay';
import { KeyboardEvents, MouseEvents, NetworkEvents } from '../Events';
import {
    CantPlayOverlay,
    CANT_PLAY_OVERLAY_ID,
    StillThereOverlay,
    STILL_THERE_OVERLAY_ID,
    INFO_BANNER_ID,
    EnterPinOverlay,
    ENTER_PIN_OVERLAY_ID,
    InternetConnectionLostOverlay,
    INTERNET_CONNECTION_LOST_OVERLAY_ID,
    IncorrectPinOverlay,
    IncorrectPinMaxRetryOverlay,
} from '../../strings';
import { InfoBanner } from '../InfoBanner';
import { PinOverlay } from '../PinOverlay';
import { NavigationHandlers } from '../../core/infrastructure/navigation/NavigationTypes';
import { PARENTAL_CONTROL_PIN_MAX_LENGTH, PARENTAL_CONTROL_PIN_MAX_RETRY } from '../../constants';

const App: FunctionComponent = (): JSX.Element => {
    const dispatch = useDispatch();
    const shakaPlayerUrl = useSelector((state: RootState) => state.stationData.shakaPlayUrl);
    const messageOverlay = useSelector((state: RootState) => state.messageOverlay);
    const {
        isCantPlayOverlayActive,
        isStillThereOverlayActive,
        isInternetConnectionLostOverlayActive,
    } = messageOverlay;
    const pinOverlay = useSelector((state: RootState) => state.pinOverlay);
    const { isEnterPinOverlayActive, parentalControlPin, invalidParentalControlPinRetryCount } =
        pinOverlay;

    useEffect(() => {
        dispatch(appStart());
    }, []);

    let shakaPlayer;
    if (shakaPlayerUrl.length > 0) {
        shakaPlayer = <ShakaPlayer />;
    }

    let pinDialog = EnterPinOverlay;
    if (
        invalidParentalControlPinRetryCount > 0 &&
        invalidParentalControlPinRetryCount <= PARENTAL_CONTROL_PIN_MAX_RETRY
    ) {
        pinDialog = IncorrectPinOverlay;
    } else if (invalidParentalControlPinRetryCount > PARENTAL_CONTROL_PIN_MAX_RETRY) {
        pinDialog = IncorrectPinMaxRetryOverlay;
    }

    return (
        <React.Fragment>
            <Welcome />
            <NetworkEvents>
                <MessageOverlay
                    isMessageOverlayActive={isInternetConnectionLostOverlayActive}
                    dialog={InternetConnectionLostOverlay}
                    id={INTERNET_CONNECTION_LOST_OVERLAY_ID}
                    handler={NavigationHandlers.LostInternetConnectionHandler}
                />
            </NetworkEvents>
            <MouseEvents />
            <KeyboardEvents>
                {shakaPlayer}
                <MessageOverlay
                    isMessageOverlayActive={isCantPlayOverlayActive}
                    dialog={CantPlayOverlay}
                    id={CANT_PLAY_OVERLAY_ID}
                    handler={NavigationHandlers.CantPlayHandler}
                />
                <MessageOverlay
                    isMessageOverlayActive={isStillThereOverlayActive}
                    dialog={StillThereOverlay}
                    id={STILL_THERE_OVERLAY_ID}
                    handler={NavigationHandlers.StillThereHandler}
                />
                <InfoBanner id={INFO_BANNER_ID} />
                <PinOverlay
                    active={isEnterPinOverlayActive}
                    dialog={pinDialog}
                    id={ENTER_PIN_OVERLAY_ID}
                    handler={NavigationHandlers.PinDialogHandler}
                    pin={parentalControlPin}
                    maxLength={PARENTAL_CONTROL_PIN_MAX_LENGTH}
                />
            </KeyboardEvents>
        </React.Fragment>
    );
};

export default App;

import { AnyAction } from 'redux';
import { NavigationHandlers } from '../../core/infrastructure/navigation/NavigationTypes';
import { setNavigationHandlerAction } from '../appl/Appl.actions';
import { Constants } from '../../constants';
import { ChannelData } from '../../core/entities/stationdata/ChannelData';
import {
    showTrickPlayBarAction,
    tpbSetCurrentPositionAction,
    tpbSetCurrentTimeAction,
    tpbSetStartEndTimeAction,
    showStandardInfoBannerAction,
    setDataStandardInfoAction,
    updateTrickPlayBarTimeoutIdAction,
    updateStandardInfoTimeoutIdAction,
    updatePlaybackControlsTimeoutIdAction,
    hideStandardInfoBannerAction,
    hideTrickPlayBarAction,
    showPlaybackControlsAction,
    hidePlaybackControlsAction,
    showFullInfoAction,
    hideFullInfoAction,
    updateFullInfoTimeoutIdAction,
    setActionItemsCountAction,
    setActionsStripHighlightIndexAction,
    setAvailableActionItemsAction,
} from './InfoBanner.actions';
import {
    TPB_SHOW_TRICK_PLAY_BAR,
    TPB_SET_CURRENT_POSITION,
    TPB_SET_CURRENT_TIME,
    TPB_SET_START_END_TIME,
    SIB_SHOW_STANDARD_INFO,
    SIB_SET_DATA_STANDARD_INFO,
    TPB_UPDATE_TRICK_PLAY_BAR_TIMER,
    TPB_SET_PLAYBACK_CONTROLS,
    SIB_UPDATE_STANDARD_INFO_TIMER,
    TPB_UPDATE_PLAYBACK_CONTROLS_TIMER,
    TPB_HIDE_TRICK_PLAY_BAR,
    SIB_HIDE_STANDARD_INFO,
    FIB_SHOW_FULL_INFO,
    FIB_HIDE_FULL_INFO,
    FIB_UPDATE_FULL_INFO_TIMEOUT_ID,
    SET_ACTIONS_STRIP_ACTION_ITEMS_COUNT,
    SET_ACTIONS_STRIP_AVAILABLE_ACTION_ITEM,
    SET_ACTIONS_STRIP_HIGHLIGHT_INDEX,
    SET_IS_OVERLAY_ACTIVE,
} from './InfoBanner.types';
import { Log } from '../../core/logging/Log';

const initialState: {
    actionsStripHighlightIndex: number;
    actionItemsCount: number;
    isOverlayActive: boolean;
    availableActionItems: Array<any>;
    startTime: Date;
    endTime: Date;
    currentTime: Date;
    currentPosition: number;
    isTrickPlayBarActive: boolean;
    isStandardInfoActive: boolean;
    channelData: ChannelData[];
    trickPlayBarTimeoutId: any;
    standardInfoTimeoutId: any;
    playbackControlsTimeoutId: any;
    isPlaybackControlsActive: boolean;
    isFullInfoActive: boolean;
    fullInfoTimeoutId: any;
} = {
    actionsStripHighlightIndex: 0,
    actionItemsCount: 0,
    isOverlayActive: false,
    availableActionItems: [],
    startTime: new Date(),
    endTime: new Date(),
    currentTime: new Date(),
    currentPosition: 0,
    isTrickPlayBarActive: false,
    isStandardInfoActive: false,
    channelData: [],
    trickPlayBarTimeoutId: null,
    standardInfoTimeoutId: null,
    playbackControlsTimeoutId: null,
    isPlaybackControlsActive: false,
    isFullInfoActive: false,
    fullInfoTimeoutId: null,
};

const TAG = 'InfoBanner';

const InfoBannerReducer = (state = initialState, { type, payload } = <AnyAction>{}) => {
    switch (type) {
        case SET_ACTIONS_STRIP_HIGHLIGHT_INDEX:
            return {
                ...state,
                actionsStripHighlightIndex: payload.actionsStripHighlightIndex,
            };
        case SET_ACTIONS_STRIP_AVAILABLE_ACTION_ITEM:
            return {
                ...state,
                availableActionItems: payload.availableActionItems,
            };
        case SET_ACTIONS_STRIP_ACTION_ITEMS_COUNT:
            return {
                ...state,
                actionItemsCount: payload.actionItemsCount,
            };
        case SET_IS_OVERLAY_ACTIVE:
            return {
                ...state,
                isOverlayActive: payload.isOverlayActive,
            };
        case TPB_SET_START_END_TIME:
            return {
                ...state,
                startTime: payload.startTime,
                endTime: payload.endTime,
            };
        case TPB_SET_CURRENT_TIME:
            return {
                ...state,
                currentTime: payload.currentTime,
            };
        case TPB_SET_CURRENT_POSITION: {
            return {
                ...state,
                currentPosition: payload.currentPosition,
            };
        }
        case TPB_SHOW_TRICK_PLAY_BAR:
        case TPB_HIDE_TRICK_PLAY_BAR: {
            return {
                ...state,
                isTrickPlayBarActive: payload.isTrickPlayBarActive,
            };
        }
        case SIB_SHOW_STANDARD_INFO:
        case SIB_HIDE_STANDARD_INFO: {
            return {
                ...state,
                isStandardInfoActive: payload.isStandardInfoActive,
                isTrickPlayBarActive: payload.isTrickPlayBarActive,
            };
        }
        case SIB_SET_DATA_STANDARD_INFO: {
            return {
                ...state,
                channelData: payload.channelData,
            };
        }
        case TPB_UPDATE_TRICK_PLAY_BAR_TIMER: {
            return {
                ...state,
                trickPlayBarTimeoutId: payload.trickPlayBarTimeoutId,
            };
        }
        case SIB_UPDATE_STANDARD_INFO_TIMER: {
            return {
                ...state,
                standardInfoTimeoutId: payload.standardInfoTimeoutId,
            };
        }
        case TPB_UPDATE_PLAYBACK_CONTROLS_TIMER: {
            return {
                ...state,
                playbackControlsTimeoutId: payload.playbackControlsTimeoutId,
            };
        }
        case TPB_SET_PLAYBACK_CONTROLS: {
            return {
                ...state,
                isPlaybackControlsActive: payload.isPlaybackControlsActive,
            };
        }
        case FIB_SHOW_FULL_INFO:
        case FIB_HIDE_FULL_INFO: {
            return {
                ...state,
                isFullInfoActive: payload.isFullInfoActive,
            };
        }
        case FIB_UPDATE_FULL_INFO_TIMEOUT_ID: {
            return {
                ...state,
                fullInfoTimeoutId: payload.fullInfoTimeoutId,
            };
        }
        default:
            return state;
    }
};

export const setActionsStripHighlightIndex =
    (actionsStripHighlightIndex: number) => (dispatch: Function) => {
        dispatch(setActionsStripHighlightIndexAction(actionsStripHighlightIndex));
        Log.i(TAG, 'setActionsStripHighlightIndex');
    };

export const setActionStripAvailableActionItems =
    (availableActionItems: Array<any>) => (dispatch: Function) => {
        dispatch(setAvailableActionItemsAction(availableActionItems));
    };

export const setActionItemsCount = (actionItemsCount: number) => (dispatch: Function) => {
    dispatch(setActionItemsCountAction(actionItemsCount));
    Log.i(TAG, 'setActionItemsCount');
};

export const showTrickPlayBar = () => (dispatch: Function, getState: Function) => {
    const { infoBanner } = getState();
    const { trickPlayBarTimeoutId, isStandardInfoActive, isTrickPlayBarActive } = infoBanner;
    if (!isStandardInfoActive) {
        if (!isTrickPlayBarActive) {
            if (trickPlayBarTimeoutId) clearTimeout(trickPlayBarTimeoutId);
            Log.i(TAG, 'Show Trick Play Bar');
            dispatch(showTrickPlayBarAction());
        }
        const timer = setTimeout(() => {
            dispatch(hideTrickPlayBar());
        }, Constants.TPB_DISPLAY_TIME);
        dispatch(updateTrickPlayBarTimeoutIdAction(timer));
    }
};

export const showTrickPlayBarWIthPlaybackControls = () => (dispatch: Function) => {
    dispatch(showPlaybackControls());
    dispatch(showTrickPlayBar());
};

export const hideTrickPlayBar = () => (dispatch: Function, getState: Function) => {
    Log.i(TAG, 'Hide Trick Play Bar');
    const { infoBanner } = getState();
    const { trickPlayBarTimeoutId, isOverlayActive } = infoBanner;
    if (!isOverlayActive) {
        dispatch(hideTrickPlayBarAction());
        dispatch(setNavigationHandlerAction(NavigationHandlers.ApplHandler));
    }
    dispatch(hidePlaybackControls());
    if (trickPlayBarTimeoutId) clearTimeout(trickPlayBarTimeoutId);
};

export const tpbSetCurrentTime = () => (dispatch: Function) => {
    dispatch(tpbSetCurrentTimeAction(new Date()));
};

export const tpbSetCurrentPosition = () => (dispatch: Function, getState: Function) => {
    const { infoBanner } = getState();
    const { currentTime, startTime } = infoBanner;

    dispatch(
        tpbSetCurrentPositionAction(
            Number(
                (
                    (Constants.PROGRESS_BAR_LENGTH *
                        (currentTime.valueOf() - startTime.valueOf())) /
                    (Constants.CONTENT_DURATION_IN_HOUR * Constants.HOUR_TO_MILISEC_CONVERTER)
                ).toFixed(3),
            ),
        ),
    );
};

export const tpbSetStartEndTime = () => (dispatch: Function, getState: Function) => {
    const { infoBanner } = getState();
    const { currentTime } = infoBanner;
    const tempCurrentTime = new Date(currentTime);
    const roundOffTime = new Date(tempCurrentTime.setMinutes(tempCurrentTime.getMinutes() - 30));
    const currenrHour = roundOffTime.getHours();
    let currentMinutes = roundOffTime.getMinutes();
    // rounding of minutes to nearest half hour
    if (Math.floor(currentMinutes / 30) === 0) {
        currentMinutes = currentMinutes < 15 ? 0 : 30;
    } else {
        currentMinutes = currentMinutes < 45 ? 30 : 60;
    }

    const newStartTime = new Date();
    newStartTime.setHours(currenrHour, currentMinutes, 0);
    const newEndTime = new Date();
    newEndTime.setHours(currenrHour + Constants.CONTENT_DURATION_IN_HOUR, currentMinutes, 0);
    dispatch(tpbSetStartEndTimeAction(new Date(newStartTime), new Date(newEndTime)));
    dispatch(tpbSetCurrentPosition());
};

export const tpbUpdateStartEndTime = () => (dispatch: Function, getState: Function) => {
    const { infoBanner } = getState();
    const { startTime, endTime } = infoBanner;
    const newStartTime = new Date(
        startTime.setMinutes(startTime.getMinutes() + Constants.ROUNDOFF_TIME_MINUTES),
    );
    const newEndTime = new Date(
        endTime.setMinutes(endTime.getMinutes() + Constants.ROUNDOFF_TIME_MINUTES),
    );
    dispatch(tpbSetStartEndTimeAction(new Date(newStartTime), new Date(newEndTime)));
    dispatch(tpbSetCurrentPosition());
};

export const toggleTrickPlayBar = () => (dispatch: Function, getState: Function) => {
    const { infoBanner } = getState();
    const { isTrickPlayBarActive, isStandardInfoActive, isOverlayActive } = infoBanner;
    if (!isTrickPlayBarActive) {
        dispatch(showTrickPlayBarWIthPlaybackControls());
    } else if (!isStandardInfoActive && !isOverlayActive) {
        dispatch(hideTrickPlayBar());
    } else {
        dispatch(showPlaybackControls());
    }
};

export const hideStandardInfoBanner = () => (dispatch: Function, getState: Function) => {
    Log.i(TAG, 'Hide Standard Info Banner');
    const { infoBanner } = getState();
    const { standardInfoTimeoutId, isOverlayActive } = infoBanner;
    if (!isOverlayActive) {
        dispatch(hideStandardInfoBannerAction());
        dispatch(setNavigationHandlerAction(NavigationHandlers.ApplHandler));
    }
    if (standardInfoTimeoutId) clearTimeout(standardInfoTimeoutId);
};

export const setDataStandardInfo = () => (dispatch: Function, getState: Function) => {
    const { stationData } = getState();
    const { channelData } = stationData;
    dispatch(setDataStandardInfoAction(channelData));
};

export const showStandardInfoBanner = () => (dispatch: Function, getState: Function) => {
    const { infoBanner } = getState();
    const { standardInfoTimeoutId, trickPlayBarTimeoutId, isFullInfoActive, isStandardInfoActive } =
        infoBanner;
    if (isFullInfoActive) dispatch(hideFullInfoBanner());
    if (trickPlayBarTimeoutId) clearTimeout(trickPlayBarTimeoutId);
    if (standardInfoTimeoutId) clearTimeout(standardInfoTimeoutId);
    Log.i(TAG, 'Show Standard Info Banner');
    if (!isStandardInfoActive) dispatch(showStandardInfoBannerAction());
    const timer = setTimeout(() => {
        dispatch(hideStandardInfoBanner());
    }, Constants.STANDARD_INFO_BANNER_DISPLAY_TIME);
    dispatch(updateStandardInfoTimeoutIdAction(timer));
};

export const updateStandardInfoBannerTimer = () => (dispatch: Function, getState: Function) => {
    const { infoBanner } = getState();
    const { standardInfoTimeoutId } = infoBanner;
    if (standardInfoTimeoutId) clearTimeout(standardInfoTimeoutId);
    Log.i(TAG, 'Standard Info Banner timeout updated');
    const timer = setTimeout(() => {
        dispatch(hideStandardInfoBanner());
    }, Constants.STANDARD_INFO_BANNER_DISPLAY_TIME);
    dispatch(updateStandardInfoTimeoutIdAction(timer));
};

export const updateTrickPlayTimer = () => (dispatch: Function, getState: Function) => {
    const { trickPlayBarTimeoutId } = getState().infoBanner;
    if (trickPlayBarTimeoutId) clearTimeout(trickPlayBarTimeoutId);
    Log.i(TAG, 'Trickplay bar timeout updated');
    const timer = setTimeout(() => {
        dispatch(hideTrickPlayBar());
    }, Constants.TPB_DISPLAY_TIME);
    dispatch(updateTrickPlayBarTimeoutIdAction(timer));
};

export const showPlaybackControls = () => (dispatch: Function, getState: Function) => {
    const { infoBanner } = getState();
    const { playbackControlsTimeoutId } = infoBanner;
    if (playbackControlsTimeoutId) clearTimeout(playbackControlsTimeoutId);
    dispatch(showPlaybackControlsAction());
    const timer = setTimeout(() => {
        dispatch(hidePlaybackControls());
    }, Constants.TPB_DISPLAY_TIME);
    dispatch(updatePlaybackControlsTimeoutIdAction(timer));
};

export const hidePlaybackControls = () => (dispatch: Function, getState: Function) => {
    dispatch(hidePlaybackControlsAction());
    const { infoBanner } = getState();
    const { playbackControlsTimeoutId } = infoBanner;
    if (playbackControlsTimeoutId) clearTimeout(playbackControlsTimeoutId);
};

export const showFullInfoBanner = () => (dispatch: Function, getState: Function) => {
    const { infoBanner } = getState();
    const { fullInfoTimeoutId, isStandardInfoActive, isTrickPlayBarActive } = infoBanner;
    if (isStandardInfoActive) dispatch(hideStandardInfoBanner());
    else if (isTrickPlayBarActive) dispatch(hideTrickPlayBar());
    if (fullInfoTimeoutId) clearTimeout(fullInfoTimeoutId);
    dispatch(showFullInfoAction());
    const timer = setTimeout(() => {
        dispatch(hideFullInfoBanner());
    }, Constants.FULL_INFO_BANNER_DISPLAY_TIME);
    dispatch(updateFullInfoTimeoutIdAction(timer));
};

export const hideFullInfoBanner = () => (dispatch: Function, getState: Function) => {
    dispatch(hideFullInfoAction());
    const { infoBanner } = getState();
    const { fullInfoTimeoutId } = infoBanner;
    if (fullInfoTimeoutId) clearTimeout(fullInfoTimeoutId);
};

export default InfoBannerReducer;

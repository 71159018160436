import { AnyAction } from 'redux';
import { AnalyticsLogger } from '../../core/logging/AnalyticsLogger';
import { QoEAppLogger } from '../../core/logging/QoEAppLogger';
import { PARENTAL_CONTROL_PIN_MAX_LENGTH } from '../../constants';
import { Log } from '../../core/logging/Log';
import { NavigationHandlers } from '../../core/infrastructure/navigation/NavigationTypes';
import { setNavigationHandler, verifyParentalControlPin } from '../appl/Appl.reducers';
import {
    hideStandardInfoBanner,
    showStandardInfoBanner,
    toggleTrickPlayBar,
    hideTrickPlayBar,
    showTrickPlayBarWIthPlaybackControls,
    updateStandardInfoBannerTimer,
    showFullInfoBanner,
    hideFullInfoBanner,
} from '../infobanner/InfoBanner.reducers';
import { cantPlayOverlayHide } from '../messageoverlay/MessageOverlay.reducers';
import {
    deleteParentalControlPin,
    enterPinOverlayHide,
    readParentalControlPin,
} from '../pinoverlay/PinOverlay.reducers';
import { stopVideoPlayback } from '../shakaplayer/ShakaPlayer.reducers';
import {
    ON_KEYUP_DIGIT,
    ON_KEYUP_DOWN,
    ON_KEYUP_EVENT,
    ON_KEYUP_INFO,
    ON_KEYUP_LEFT,
    ON_KEYUP_OK,
    ON_KEYUP_RIGHT,
    ON_KEYUP_TIVO,
    ON_KEYUP_UP,
} from './inputFactory.types';
import {
    onKeyBackActionsStrip,
    onKeyLeftActionsStrip,
    onKeyOkActionsStrip,
    onKeyRightActionsStrip,
} from '../infobanner/ActionsStripNavigation.reducers';
import {
    onKeyBackOverlay,
    onKeyDownOverlay,
    onKeyOkOverlay,
    onKeyUpOverlay,
} from '../overlay/overlaynavigation/overlaynavigation.reducers';

const TAG = 'inputFactory';
const initialState: {
    key: string;
    code: string;
} = {
    key: '',
    code: '',
};

const inputFactoryReducer = (state = initialState, { type, payload } = <AnyAction>{}) => {
    switch (type) {
        case ON_KEYUP_EVENT:
            return { ...state, key: payload.key, code: payload.code };
        case ON_KEYUP_RIGHT:
        case ON_KEYUP_LEFT:
        case ON_KEYUP_UP:
        case ON_KEYUP_DOWN:
        case ON_KEYUP_INFO:
        case ON_KEYUP_TIVO:
        case ON_KEYUP_DIGIT:
        case ON_KEYUP_OK:
            return { ...state, ...payload };
        default:
            return state;
    }
};

export const onKeyRight = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    const { navigationHandler } = appl;

    switch (navigationHandler) {
        case NavigationHandlers.ApplHandler:
            dispatch(setNavigationHandler(NavigationHandlers.ActionStripHandler));
            dispatch(showTrickPlayBarWIthPlaybackControls());
            break;
        case NavigationHandlers.PinDialogHandler:
            // TODO: provide right key behaviour for PIN dialog.
            Log.i(TAG, `: Bonk`);
            break;
        case NavigationHandlers.ActionStripHandler:
            dispatch(onKeyRightActionsStrip());
            break;
        default:
    }
};

export const onKeyLeft = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    const { navigationHandler } = appl;
    switch (navigationHandler) {
        case NavigationHandlers.PinDialogHandler:
            dispatch(deleteParentalControlPin());
            break;
        case NavigationHandlers.ApplHandler:
            dispatch(setNavigationHandler(NavigationHandlers.ActionStripHandler));
            dispatch(showTrickPlayBarWIthPlaybackControls());
            break;
        case NavigationHandlers.ActionStripHandler:
            dispatch(onKeyLeftActionsStrip());
            break;
        default:
    }
};

export const onKeyUp = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    const { navigationHandler } = appl;
    switch (navigationHandler) {
        case NavigationHandlers.ApplHandler:
            dispatch(toggleTrickPlayBar());
            break;
        case NavigationHandlers.PinDialogHandler:
            // TODO: provide arrow up key behaviour for PIN dialog.
            Log.i(TAG, `: KeyUp Bonk`);
            break;
        case NavigationHandlers.OverlayNavigationHandler:
            dispatch(onKeyUpOverlay());
            break;
        default:
    }
};

export const onKeyDown = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    const { navigationHandler } = appl;
    switch (navigationHandler) {
        case NavigationHandlers.ApplHandler:
            dispatch(toggleTrickPlayBar());
            break;
        case NavigationHandlers.PinDialogHandler:
            // TODO: provide arrow down key behaviour for PIN dialog.
            Log.i(TAG, `: KeyDown Bonk`);
            break;
        case NavigationHandlers.OverlayNavigationHandler:
            dispatch(onKeyDownOverlay());
            break;
        default:
    }
};

export const onKeyInfo = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    const { navigationHandler } = appl;
    if (navigationHandler === NavigationHandlers.ApplHandler) {
        dispatch(showFullInfoBanner());
    }

    if (navigationHandler === NavigationHandlers.PinDialogHandler) {
        // TODO: provide info key behaviour for PIN dialog.
        Log.i(TAG, `: Bonk`);
    }
};

export const onKeyTivo = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    const { navigationHandler } = appl;
    if (navigationHandler === NavigationHandlers.ApplHandler) {
        // TODO: implementation for TiVo key event behaviour in the app.
    }

    if (navigationHandler === NavigationHandlers.PinDialogHandler) {
        // TODO: provide tivo key behaviour for PIN dialog.
        Log.i(TAG, `: Bonk`);
    }
};

export const onKeyOk = () => (dispatch: Function, getState: Function) => {
    const { appl, infoBanner } = getState();
    const { navigationHandler } = appl;
    const { isTrickPlayBarActive, isStandardInfoActive } = infoBanner;
    switch (navigationHandler) {
        case NavigationHandlers.ApplHandler:
            dispatch(setNavigationHandler(NavigationHandlers.ActionStripHandler));
            if (isStandardInfoActive) {
                dispatch(onKeyOkActionsStrip());
                dispatch(updateStandardInfoBannerTimer());
            } else if (isTrickPlayBarActive) {
                dispatch(onKeyOkActionsStrip());
            } else {
                dispatch(showStandardInfoBanner());
            }
            break;
        case NavigationHandlers.ActionStripHandler:
            dispatch(onKeyOkActionsStrip());
            break;
        case NavigationHandlers.OverlayNavigationHandler:
            dispatch(onKeyOkOverlay());
            break;
        case NavigationHandlers.MessageOverlayHandler:
            // TODO: implementation for ok key event behaviour when message overlay is up.
            // NOTE: the case will eventually will be removed once we have the implemenations
            // for Can't Play, Still There, and Network Overlays.
            // TODO: To be uncommented once ok key event behaviour is implemented.
            // NOTE: For QoE watchContentEnd, streamingEnd and segmentLogging appExit event.
            // dispatch(stopVideoPlayback(QoEAppLogger.PlaybackEndReasons.ENDED_BY_USER));
            // AnalyticsLogger.logAppExitEvent();
            break;
        case NavigationHandlers.CantPlayHandler:
            dispatch(cantPlayOverlayHide());
            dispatch(setNavigationHandler(NavigationHandlers.SilentHandler));
            break;
        case NavigationHandlers.LostInternetConnectionHandler:
            // TODO: provide ok key behaviour for Lost Internet Connection overlay.
            break;
        case NavigationHandlers.PinDialogHandler:
            // TODO: provide ok key behaviour for PIN dialog.
            Log.i(TAG, `: Bonk`);
            break;
        default:
            dispatch(setNavigationHandler(NavigationHandlers.ApplHandler));
    }
};

export const onKeyDigit = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    const { navigationHandler } = appl;

    if (navigationHandler === NavigationHandlers.PinDialogHandler) {
        const { inputFactory, pinOverlay } = getState();
        const { key } = inputFactory;
        const { parentalControlPin } = pinOverlay;

        dispatch(readParentalControlPin(key));

        if (parentalControlPin.length === PARENTAL_CONTROL_PIN_MAX_LENGTH) {
            dispatch(setNavigationHandler(NavigationHandlers.SilentHandler));
            dispatch(verifyParentalControlPin());
        }
    }
};

export const onKeyBack = () => (dispatch: Function, getState: Function) => {
    const { appl, infoBanner } = getState();
    const { navigationHandler } = appl;
    const {
        isOverlayActive,
        isTrickPlayBarActive,
        isStandardInfoActive,
        isPlaybackControlsActive,
        isFullInfoActive,
    } = infoBanner;
    switch (navigationHandler) {
        case NavigationHandlers.ApplHandler:
            dispatch(hideTrickPlayBar());
            dispatch(hideStandardInfoBanner());
            dispatch(hideFullInfoBanner());
            break;
        case NavigationHandlers.PinDialogHandler:
            dispatch(enterPinOverlayHide());
            break;
        case NavigationHandlers.OverlayNavigationHandler:
            dispatch(onKeyBackOverlay());
            break;
        case NavigationHandlers.ActionStripHandler:
            dispatch(onKeyBackActionsStrip());
            break;
        default:
    }

    const isAppExit =
        !isFullInfoActive &&
        !isOverlayActive &&
        !isPlaybackControlsActive &&
        !isStandardInfoActive &&
        !isTrickPlayBarActive;
    if (isAppExit) {
        dispatch(stopVideoPlayback(QoEAppLogger.PlaybackEndReasons.ENDED_BY_USER));
        AnalyticsLogger.logWatchStoppedEvent('ENDED_BY_USER');
        AnalyticsLogger.logAppExitEvent();
    }
};

export default inputFactoryReducer;

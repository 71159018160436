import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    tpbSetCurrentTime,
    tpbSetStartEndTime,
    tpbUpdateStartEndTime,
    tpbSetCurrentPosition,
} from '../../../../store/infobanner/InfoBanner.reducers';
import { RootState } from '../../../../store/root.reducers';
import { Constants } from '../../../../constants';
import './ProgressBar.scss';
import { Log } from '../../../../core/logging/Log';
import { TIME_PHASE_AM, TIME_PHASE_PM } from '../../../../strings';

const ProgressBar: FunctionComponent = (): JSX.Element => {
    const TAG = 'ProgressBar';
    const dispatch = useDispatch();
    const { currentTime, startTime, endTime, currentPosition } = useSelector(
        (state: RootState) => state.infoBanner,
    );

    // time format for display : hh:mm<time-phase>
    // TODO: make common util for formating time
    const formatTime = (date) => {
        const mins = String(date.getMinutes()).padStart(2, '0');
        const hour = date.getHours() % 12;
        const timePhase = Math.floor(date.getHours() / 12) ? TIME_PHASE_PM : TIME_PHASE_AM;
        return `${hour > 0 ? hour : '12'}:${mins}${timePhase}`;
    };

    function refreshClock() {
        dispatch(tpbSetCurrentTime());
    }

    useEffect(() => {
        dispatch(tpbSetCurrentTime());
        dispatch(tpbSetStartEndTime());
        const timerId = setInterval(refreshClock, Constants.CLOCK_REFRESH_TIME);
        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);

    useEffect(() => {
        const shouldSetShartEndTime =
            startTime.getHours() === endTime.getHours() ||
            currentTime - startTime > 60 * Constants.MINS_TO_MILISEC_CONVERTER ||
            currentTime - startTime < 0 ||
            currentTime - startTime <= 15 * Constants.MINS_TO_MILISEC_CONVERTER;

        if (shouldSetShartEndTime) {
            dispatch(tpbSetStartEndTime());
            Log.i(TAG, 'Start and End time updated');
        } else if (currentTime - startTime > Constants.ROUNDOFF_TIME_DIFFERENECE) {
            /**
             * last check mark is at 45mins from start time for live content,
             * if current time reaches to last mark, update the start,end time for new positions
             */
            dispatch(tpbUpdateStartEndTime());
            Log.i(TAG, 'Last check mark reached, Start and End time updated');
        } else {
            dispatch(tpbSetCurrentPosition());
        }
    }, [currentTime]);

    return (
        <React.Fragment>
            <div className="tpb-current-time-cointainer" style={{ marginLeft: currentPosition }}>
                <div className="tpb-current-time">
                    <span>{formatTime(currentTime)}</span>
                    <div className="tpb-current-time-play-button" />
                </div>
                <div className="tpb-current-position" />
            </div>
            <span className="tpb-start-time">{formatTime(startTime)}</span>
            <div className="tpb-progress-bar-with-progress-marks">
                <div className="tpb-progress-bar" />
                <div className="tpb-progress-marks">
                    <div className="progress-indicator-mark" style={{ marginLeft: `25%` }} />
                    <div className="progress-indicator-mark" style={{ marginLeft: `50%` }} />
                    <div className="progress-indicator-mark" style={{ marginLeft: `75%` }} />
                </div>
            </div>
            <span className="tpb-end-time">{formatTime(endTime)}</span>
        </React.Fragment>
    );
};

export default ProgressBar;

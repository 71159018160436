import { AnyAction } from 'redux';
import { ChannelData } from '../../core/entities/stationdata/ChannelData';
import {
    STATION_DATA_FETCH_REQUEST_FAILURE,
    STATION_DATA_FETCH_REQUEST,
    STATION_DATA_STORE,
    STATION_DATA_FETCH_REQUEST_RETRY,
} from './StationData.types';

export const fetchStationDataAction = (): AnyAction => ({
    type: STATION_DATA_FETCH_REQUEST,
});

export const retryFetchStationDataAction = (retry: number): AnyAction => ({
    type: STATION_DATA_FETCH_REQUEST_RETRY,
    payload: {
        retry,
    },
});

export const storeStationDataAction = (
    channelData: ChannelData[],
    shakaPlayUrl: string,
): AnyAction => ({
    type: STATION_DATA_STORE,
    payload: {
        channelData,
        shakaPlayUrl,
    },
});

export const failureStationDataAction = (): AnyAction => ({
    type: STATION_DATA_FETCH_REQUEST_FAILURE,
    payload: {
        retry: 0,
    },
});

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// NOTE: Uncomment the below import statement in case you need to debug the redux store.
import { composeWithDevTools } from 'redux-devtools-extension';
import RootReducer from './root.reducers';

// NOTE: Uncoment the below line in case you need to debug the redux store.
const TiVoPlusStore = createStore(RootReducer, composeWithDevTools(applyMiddleware(thunk)));

// NOTE: Comment the below line in case you need to debug the redux store.
// const TiVoPlusStore = createStore(RootReducer, applyMiddleware(thunk));

export default TiVoPlusStore;

import { Diagnostics } from '@tivocorp/tivo-webapp-loggers';
import { IMetaDataRepository } from '../../entities/metadata/IMetaDataRepository';
import { MetaData } from '../../entities/metadata/MetaData';
import { APS_GUIDE_URL } from '../../../strings';
import { Log } from '../../logging/Log';

export class MetaDataRepositoryImpl implements IMetaDataRepository {
    url!: string;

    authToken: string;

    stationId: string;

    TAG: string = 'MetaDataRepositoryImpl';

    constructor(authToken: string, stationId: string) {
        this.authToken = authToken;

        this.stationId = stationId;
    }

    async getMetaData(): Promise<MetaData[]> {
        const START_TIME_OFFSET = 0;
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: this.authToken,
                },
            };
            // stationId and startTimeOffset name should be as per the standard.
            const params = { stationId: this.stationId, startTimeOffset: START_TIME_OFFSET };
            this.url = APS_GUIDE_URL;
            this.url +=
                (this.url.indexOf('?') === -1 ? '?' : '&') +
                Object.keys(params)
                    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
                    .join('&');
            Log.i(this.TAG, 'METADATA URL->', this.url);
            const res = await fetch(this.url, options);
            const responseJson = await res.json();
            return responseJson.linearOffer.map((data) => new MetaData(data));
        } catch (error) {
            Log.e(this.TAG, 'Either the StationID is wrong or metadata is not coming!!', error);
            Diagnostics.error(
                this.TAG,
                'Either the StationID is wrong or metadata is not coming!!',
                error,
            );
            throw new Error('Cant find MetaData');
        }
    }
}

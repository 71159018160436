/**
 * class  for ChannelData object.
 */
export class ChannelData {
    /*
     * Channel Name
     */
    channelName: string;

    /*
     * Channel Number
     */
    channelNumber: number;

    /*
     * StationId
     */
    stationId: string;

    /*
     * Channel Order
     */
    channelOrder: number;

    /*
     * URL for play data
     */
    playUrl: string;

    /*
     * Encoded Channel Name
     */
    channelNameEncoded: string;

    /*
     * Type of content
     */
    contentType: string;

    /*
     * Description
     */
    description: string;

    /*
     * Genre
     */
    genre: string[];

    /*
     * Provider Name
     */
    providerName: string;

    /*
     * Should hide linear schedules
     */
    hideLinearSchedules: boolean;

    /*
     * Additional parameters for playback
     */
    playbackParameters: any;

    /**
     * channel logourl
     */
    channelLogoUrl: string;

    constructor(channelData: any) {
        this.channelName = channelData.channelName;
        this.channelNumber = channelData.channelNumber;
        this.stationId = channelData.stationId;
        this.channelOrder = channelData.channelOrder;
        this.playUrl = channelData.playUrl;
        this.channelNameEncoded = channelData.channelNameEncoded;
        this.contentType = channelData.contentType;
        this.description = channelData.description;
        this.genre = channelData.genre;
        this.hideLinearSchedules = channelData.hideLinearSchedules;
        this.playbackParameters = channelData.playbackParameters;
        this.providerName = channelData.providerName;
        this.channelLogoUrl = `http://i-stg.tivo.com/images-staging/images/${this.stationId}/channelLogo_258x114.png`; // TODO: Image base URL has to be read from oyster homeApp interface
    }
}

import { MetaData } from '../entities/metadata/MetaData';
import { AM, PM, TITLE_NOT_AVAILABLE } from '../../strings';
import { ChannelData } from '../entities/stationdata/ChannelData';

export class MetadataFormatter {
    private metaData: MetaData;

    private channelData: ChannelData;

    private criticRatingReleaseYearSeperator: boolean = false;

    private releaseYearGenreSeperator: boolean = false;

    private genreDurationSeperator: boolean = false;

    private durationRatingSeperator: boolean = false;

    private genreRatingSeperator: boolean = false;

    private ratingDurationSeperator: boolean = false;

    private durationFirstAiredSeperator: boolean = false;

    private isMetaDataAvialable: boolean = false;

    private isChannelDataAvialable: boolean = false;

    private isSubtitleAvailable: boolean = false;

    private ratings: Map<string, string> = new Map();

    private title: string = '';

    private hideLinearSchedules: boolean = false;

    private startTime: string = '';

    private endTime: string = '';

    private genre: string = '';

    private criticRating: string = '';

    private rating: string = '';

    private firstAired: string = '';

    private description = '';

    private subtitle: string = '';

    constructor(metaData: MetaData, channelData: ChannelData) {
        if (metaData) this.isMetaDataAvialable = true;
        if (channelData) this.isChannelDataAvialable = true;
        this.metaData = metaData;
        this.channelData = channelData;
        this._formatDescription();
        this._evaluateMetaDataSeperators();
        this._createRatingsMap();
        this._formatTitle();
        this._setHideLinearSchedules();
        this._formatStartTime();
        this._formatEndTime();
        this._formatGenres();
        this._formatCriticRating();
        this._formatRatings();
        this._formatFirstAired();
        this._formatSubtitle();
    }

    hasMetaData = () => {
        return this.isMetaDataAvialable;
    };

    needCriticRatingReleaseYearSeperator = () => {
        return this.criticRatingReleaseYearSeperator;
    };

    needReleaseYearGenreSeperator = () => {
        return this.releaseYearGenreSeperator;
    };

    needGenreDurationSeperator = () => {
        return this.genreDurationSeperator;
    };

    needRatingDurationSeperator = () => {
        return this.ratingDurationSeperator;
    };

    needDurationFirstAiredSeperator = () => {
        return this.durationFirstAiredSeperator;
    };

    needDurationRatingSeperator = () => {
        return this.durationRatingSeperator;
    };

    needGenreRatingSeperator = () => {
        return this.genreRatingSeperator;
    };

    hasReleaseYear = () => {
        return !!(this.metaData && this.metaData.releaseYear);
    };

    getReleaseYear = () => {
        return this.metaData.releaseYear;
    };

    getContentType = () => {
        return this.metaData.contentType;
    };

    isHideLinearSchedules = () => {
        return this.hideLinearSchedules;
    };

    private _setHideLinearSchedules = () => {
        if (this.hasChannelData() && this.channelData.hideLinearSchedules)
            this.hideLinearSchedules = this.channelData.hideLinearSchedules;
    };

    private _formatTitle = () => {
        this.title =
            (this.metaData && this.metaData.title
                ? this.metaData.title
                : this.hasChannelData() && this.channelData.channelName) || TITLE_NOT_AVAILABLE;
    };

    getTitle = (): string => {
        return this.title;
    };

    private _formatDescription = () => {
        if (this.metaData && this.metaData.seasonNumber)
            this.description += `S${this.metaData.seasonNumber} `;
        if (this.metaData && this.metaData.episodeNumber)
            this.description += `E${this.metaData.episodeNumber} `;
        if (this.metaData && this.metaData.shortDescription)
            this.description += `${this.metaData.shortDescription}`;
        else if (this.hasChannelData() && this.channelData.description)
            this.description += `${this.channelData.description}`;
    };

    getDescription = (): string => {
        return this.description;
    };

    hasDuration = () => {
        return !!(
            (this.metaData && this.metaData.duration) ||
            (this.hasStartTime() && this.hasEndTime())
        );
    };

    hasStartTime = () => {
        return !!(this.metaData && this.metaData.startTimestamp);
    };

    private _formatStartTime = () => {
        if (this.metaData && this.metaData.startTimestamp)
            this.startTime = this._createFormattedampmTime(this.metaData.startTimestamp * 1000);
    };

    getStartTime = (): string => {
        return this.startTime;
    };

    hasEndTime = () => {
        return !!(this.metaData && this.metaData.endTimestamp);
    };

    private _formatEndTime = () => {
        if (this.metaData && this.metaData.endTimestamp)
            this.endTime = this._createFormattedampmTime(this.metaData.endTimestamp * 1000);
    };

    getEndTime = () => {
        return this.endTime;
    };

    hasGenre = () => {
        return !!(this.metaData && this.metaData.genres && this.metaData.genres.length);
    };

    private _formatGenres = () => {
        let genre = '';
        const isChannelType =
            this.hasChannelData() &&
            this.channelData.hideLinearSchedules &&
            this.channelData.genre &&
            this.channelData.genre.length;

        if (this.hasGenre()) {
            genre += this._toWordCase(this.metaData.genres[0]);
        } else if (isChannelType) {
            const noOfGenres = this.channelData.genre.length;
            for (let i = 0; i < noOfGenres; i += 1) {
                if (i >= 1) genre += `, ${this._toWordCase(this.channelData.genre[i])}`;
                else genre += this._toWordCase(this.channelData.genre[i]);
            }
        }
        this.genre = genre;
    };

    getGenre = () => {
        return this.genre;
    };

    private _toWordCase = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    };

    hasFirstAired = () => {
        return !!(this.metaData && this.metaData.originalAirDate);
    };

    private _formatFirstAired = () => {
        let originalAirDate;
        if (this.metaData && this.metaData.originalAirDate) {
            const re = /-/gi;
            const firstAiredDate = this.metaData.originalAirDate.substring(2);
            originalAirDate = this.metaData.originalAirDate ? `First Aired: ${firstAiredDate}` : '';
            originalAirDate = originalAirDate?.replaceAll(re, '/');
        }
        this.firstAired = originalAirDate;
    };

    getFirstAired = (): string => {
        return this.firstAired;
    };

    hasRating = () => {
        this._createRatingsMap();
        return this.ratings.size > 0;
    };

    getRatings = (): string => {
        return this.rating;
    };

    private _createRatingsMap = () => {
        if (this.metaData && this.metaData.ratings && this.metaData.ratings.length) {
            const _ratings: string[] = this.metaData.ratings || [];
            _ratings.forEach((rating) => {
                this.ratings.set(
                    rating.substring(0, rating.indexOf(':')),
                    rating.substring(rating.indexOf(':') + 1),
                );
            });
        }
    };

    private _formatRatings = () => {
        if (this.ratings.has('usTv'))
            this.rating = this.ratings.has('usTv')
                ? this.ratings.get('usTv')?.toLowerCase()!
                : this.ratings.get('mpaaRating')?.toLowerCase()!;
    };

    hasCriticRating = () => {
        return !!(this.metaData && this.metaData.criticRating && this.metaData.criticRating.length);
    };

    private _formatCriticRating = () => {
        if (this.metaData && this.metaData.criticRating && this.metaData.criticRating.length) {
            this.criticRating = this.metaData.criticRating[0].get('tmdb')!;
        }
    };

    getCriticRating = (): string => {
        return this.criticRating;
    };

    getCriticRatingColor = (criticRatings: any) => {
        let criticRatingcolor;
        if (criticRatings >= 70) criticRatingcolor = 'critic-rating-green';
        else if (criticRatings >= 40) criticRatingcolor = 'critic-rating-yellow';
        else if (criticRatings > 1) criticRatingcolor = 'critic-rating-red';
        return criticRatingcolor;
    };

    private _evaluateMetaDataSeperators = () => {
        if (!this.hasMetaData()) return;

        this.criticRatingReleaseYearSeperator = this.hasCriticRating() && this.hasReleaseYear();
        this.releaseYearGenreSeperator =
            (this.hasCriticRating() || this.hasReleaseYear()) && this.hasGenre();
        this.genreDurationSeperator =
            (this.hasCriticRating() || this.hasReleaseYear() || this.hasGenre()) &&
            this.hasDuration();
        this.durationRatingSeperator =
            (this.hasCriticRating() ||
                this.hasReleaseYear() ||
                this.hasGenre() ||
                this.hasDuration()) &&
            this.hasRating();
        this.genreRatingSeperator = this.hasGenre() && this.hasRating();
        this.ratingDurationSeperator = (this.hasGenre() || this.hasRating()) && this.hasDuration();
        this.durationFirstAiredSeperator =
            (this.hasGenre() || this.hasRating() || this.hasDuration()) && this.hasFirstAired();
        this.isMetaDataAvialable =
            this.hasGenre() || this.hasRating() || this.hasDuration() || this.hasFirstAired();
    };

    createFormattedAMPMTime = (date) => {
        const mins = String(date.getMinutes()).padStart(2, '0');
        const hour = date.getHours() % 12;
        const timePhase = Math.floor(date.getHours() / 12) ? PM : AM;
        return `${hour > 0 ? hour : '12'}:${mins}${timePhase}`;
    };

    private _createFormattedampmTime = (timeStamp: any) => {
        const date = new Date(timeStamp);
        const mins = String(date.getMinutes()).padStart(2, '0');
        const hour = date.getHours() % 12;
        const timePhase = Math.floor(date.getHours() / 12) ? 'pm' : 'am';
        return `${hour > 0 ? hour : '12'}:${mins}${timePhase}`;
    };

    private _formatSubtitle = () => {
        let subtitle = '';
        if (this.hasMetaData()) {
            if (this.metaData.seasonNumber && this.metaData.seasonNumber.length > 0) {
                subtitle += `S${this.metaData.seasonNumber} `;
            }

            if (this.metaData.episodeNumber && this.metaData.episodeNumber.length > 0) {
                subtitle += `E${this.metaData.episodeNumber} `;
            }
            if (this.metaData.episodeTitle && this.metaData.episodeTitle.length > 0) {
                subtitle += `'${this.metaData.episodeTitle}'`;
            }
        }
        this.subtitle = subtitle;
        this.isSubtitleAvailable = subtitle.length > 0;
    };

    getSubtitle = (): string => {
        return this.subtitle;
    };

    hasSubtitle = (): boolean => {
        return this.isSubtitleAvailable;
    };

    getChannelLogoUrl = (): string => {
        return this.channelData?.channelLogoUrl;
    };

    hasChannelData = (): boolean => {
        return this.isChannelDataAvialable;
    };
}

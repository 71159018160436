import { AnyAction } from 'redux';
import {
    ENTER_PIN_OVERLAY_SHOW,
    ENTER_PIN_OVERLAY_HIDE,
    READ_PARENTAL_CONTROL_PIN,
    DELETE_PARENTAL_CONTROL_PIN,
    CLEAR_PARENTAL_CONTROL_PIN,
    VALID_PARENTAL_CONTROL_PIN,
    INVALID_PARENTAL_CONTROL_PIN,
} from './PinOverlay.types';

export const enterPinOverlayShowAction = (): AnyAction => ({
    type: ENTER_PIN_OVERLAY_SHOW,
    payload: {
        isEnterPinOverlayActive: true,
    },
});

export const enterPinOverlayHideAction = (): AnyAction => ({
    type: ENTER_PIN_OVERLAY_HIDE,
    payload: {
        isEnterPinOverlayActive: false,
    },
});

export const readParentalControlPinAction = (parentalControlPin: []): AnyAction => ({
    type: READ_PARENTAL_CONTROL_PIN,
    payload: {
        parentalControlPin,
    },
});

export const deleteParentalControlPinAction = (parentalControlPin: []): AnyAction => ({
    type: DELETE_PARENTAL_CONTROL_PIN,
    payload: {
        parentalControlPin,
    },
});

export const clearParentalControlPinAction = (): AnyAction => ({
    type: CLEAR_PARENTAL_CONTROL_PIN,
    payload: {
        parentalControlPin: [],
    },
});

export const validParentalControlPinAction = (): AnyAction => ({
    type: VALID_PARENTAL_CONTROL_PIN,
    payload: {
        isValidParentalControlPin: true,
        invalidParentalControlPinRetryCount: 0,
    },
});

export const invalidParentalControlPinAction = (retryCount: number): AnyAction => ({
    type: INVALID_PARENTAL_CONTROL_PIN,
    payload: {
        invalidParentalControlPinRetryCount: retryCount,
    },
});

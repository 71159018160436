import { AnyAction } from 'redux';
import {
    SET_OVERLAY_HIGHLIGHT_VERTICAL_LIMIT,
    SET_OVERLAY_HIGHLIGHT_VERTICAL_POSITION,
} from './overlayhighlight.types';

export const setOverlayHighlightVerticalPositionAction = (verticalPosition: number): AnyAction => ({
    type: SET_OVERLAY_HIGHLIGHT_VERTICAL_POSITION,
    payload: {
        verticalPosition,
    },
});

export const setOverlayHighlightVerticalLimitAction = (verticalLimit: number): AnyAction => ({
    type: SET_OVERLAY_HIGHLIGHT_VERTICAL_LIMIT,
    payload: { verticalLimit },
});

import { AnyAction } from 'redux';
import { HIDE_AUDIO_TRACK_OVERLAY, SHOW_AUDIO_TRACK_OVERLAY } from './audiotrackoverlay.types';

export const showAudioTrackOverlayAction = (trackList: any): AnyAction => ({
    type: SHOW_AUDIO_TRACK_OVERLAY,
    payload: {
        isAudioTrackOverlayActive: true,
        audioTrackList: trackList,
    },
});

export const hideAudioTrackOverlayAction = (): AnyAction => ({
    type: HIDE_AUDIO_TRACK_OVERLAY,
    payload: {
        isAudioTrackOverlayActive: false,
        audioTrackList: [],
    },
});

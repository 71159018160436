import { AnyAction } from 'redux';
import {
    SHAKA_PLAYER_START_PLAYBACK,
    SHAKA_PLAYER_RESTART_PLAYBACK,
    SHAKA_PLAYER_TOGGLE_CLOSED_CAPTION,
    SHAKA_PLAYER_RESET_RETRY,
} from './ShakaPlayer.types';
import {
    shakaPlayerStartPlaybackAction,
    shakaPlayerRestartPlaybackAction,
    shakaPlayerSetClosedCaptionAction,
    shakaPlayerResetRetryAction,
} from './ShakaPlayer.actions';
import { Log } from '../../core/logging/Log';
import { cantPlayOverlayShow } from '../messageoverlay/MessageOverlay.reducers';
import { Constants } from '../../constants';
import { QoEAppLogger, AnalyticsLogger } from '../../core/logging';

const initialState: {
    playUrl: string;
    cc: boolean;
    retry: number;
} = {
    playUrl: '',
    cc: false,
    retry: 0,
};
const TAG = 'ShakaPlayerReducer';
const shakaPlayerReducer = (state = initialState, { type, payload } = <AnyAction>{}) => {
    switch (type) {
        case SHAKA_PLAYER_START_PLAYBACK:
            return {
                ...state,
                playUrl: payload.playUrl,
                retry: 0,
            };

        case SHAKA_PLAYER_RESTART_PLAYBACK:
            return {
                ...state,
                playUrl: payload.playUrl,
                retry: payload.retry,
            };

        case SHAKA_PLAYER_TOGGLE_CLOSED_CAPTION:
            return {
                ...state,
                cc: payload.cc,
            };

        case SHAKA_PLAYER_RESET_RETRY:
            return {
                ...state,
                retry: 0,
            };

        default:
            return state;
    }
};

export const startVideoPlayback = (playUrl: string) => (dispatch: Function) => {
    dispatch(shakaPlayerStartPlaybackAction(playUrl));
};

export const updateStationAndMetaData = () => (dispatch: Function, getState: Function) => {
    const { stationData, metaData: oMetadata } = getState();
    const { channelData } = stationData;
    const { metaData } = oMetadata;
    QoEAppLogger.updateContentPayload(channelData[0], metaData[0]);
    AnalyticsLogger.updateAnalyticsContentPayload(channelData[0], metaData[0]);
};

export const retryVideoPlayback = () => (dispatch: Function, getState: Function) => {
    const { shakaPlayer } = getState();
    const { playUrl, retry } = shakaPlayer;
    dispatch(shakaPlayerRestartPlaybackAction(playUrl, retry + 1));
};

export const toggleClosedCaption = () => (dispatch: Function, getState: Function) => {
    const { shakaPlayer } = getState();
    const { cc } = shakaPlayer;
    dispatch(shakaPlayerSetClosedCaptionAction(!cc));
};

export const stopVideoPlayback = (endReason: string) => () => {
    // set endReason and log transation end events
    QoEAppLogger.setEndReasonAndLogEvent(endReason);
};

export const resetPlaybackRetry = () => (dispatch: Function) => {
    dispatch(shakaPlayerResetRetryAction());
};

export const handleVideoPlayerError =
    (mVideoPlayer: any) => (dispatch: Function, getState: Function) => {
        const { retry } = getState().shakaPlayer;
        const RETRY_LIMIT = 5;
        if (retry < RETRY_LIMIT) {
            mVideoPlayer.unload();
            Log.d(TAG, 'Retry attempt:', retry);
            setTimeout(() => {
                dispatch(retryVideoPlayback());
            }, Constants.TIMEOUT_VALUE);
        } else {
            dispatch(cantPlayOverlayShow());
            QoEAppLogger.updateAllHealthStatsMetrics(mVideoPlayer.getStats());
            QoEAppLogger.setEndReasonAndLogEvent(QoEAppLogger.PlaybackEndReasons.PLAYER_ERROR);
            Log.e(TAG, 'Failed to start the playback');
        }
    };

export default shakaPlayerReducer;

export class Keys {
    // List of supported keyboard keys.
    static KEYBOARD_KEY_UP: string = 'arrowup';

    static KEYBOARD_KEY_DOWN: string = 'arrowdown';

    static KEYBOARD_KEY_LEFT: string = 'arrowleft';

    static KEYBOARD_KEY_RIGHT: string = 'arrowright';

    static KEYBOARD_KEY_TIVO: string = 't';

    static KEYBOARD_KEY_INFO: string = 'i';

    static KEYBOARD_KEY_OK: string = 'enter';

    static KEYBOARD_KEY_0: string = '0';

    static KEYBOARD_KEY_1: string = '1';

    static KEYBOARD_KEY_2: string = '2';

    static KEYBOARD_KEY_3: string = '3';

    static KEYBOARD_KEY_4: string = '4';

    static KEYBOARD_KEY_5: string = '5';

    static KEYBOARD_KEY_6: string = '6';

    static KEYBOARD_KEY_7: string = '7';

    static KEYBOARD_KEY_8: string = '8';

    static KEYBOARD_KEY_9: string = '9';

    static KEYBOARD_KEY_BACK: string = 'escape';

    // TODO: provide the mapping of remote keys.
}

import { AnyAction } from 'redux';
import {
    ENTER_PIN_OVERLAY_SHOW,
    ENTER_PIN_OVERLAY_HIDE,
    READ_PARENTAL_CONTROL_PIN,
    DELETE_PARENTAL_CONTROL_PIN,
    CLEAR_PARENTAL_CONTROL_PIN,
    INVALID_PARENTAL_CONTROL_PIN,
    VALID_PARENTAL_CONTROL_PIN,
} from './PinOverlay.types';
import {
    deleteParentalControlPinAction,
    enterPinOverlayHideAction,
    enterPinOverlayShowAction,
    readParentalControlPinAction,
    clearParentalControlPinAction,
    validParentalControlPinAction,
    invalidParentalControlPinAction,
} from './PinOverlay.actions';
import { Log } from '../../core/logging/Log';
import { PARENTAL_CONTROL_PIN_MAX_LENGTH } from '../../constants';
import { Storage } from '../../core/storage/Storage';

const TAG = 'PinOverlay';
const storage = Storage.getInstance();
const initialState: {
    isEnterPinOverlayActive: boolean;
    parentalControlPin: Array<[]>;
    isValidParentalControlPin: boolean;
    invalidParentalControlPinRetryCount: number;
} = {
    isEnterPinOverlayActive: false,
    parentalControlPin: [],
    isValidParentalControlPin: false,
    invalidParentalControlPinRetryCount:
        Number(storage.get(Storage.Keys.INVALID_PC_PIN_RETRY_COUNT)) || 0,
};

export const pinOverlayReducer = (state = initialState, { type, payload } = <AnyAction>{}) => {
    switch (type) {
        case ENTER_PIN_OVERLAY_SHOW:
        case ENTER_PIN_OVERLAY_HIDE:
            return {
                ...state,
                isEnterPinOverlayActive: payload.isEnterPinOverlayActive,
            };

        case READ_PARENTAL_CONTROL_PIN:
        case DELETE_PARENTAL_CONTROL_PIN:
        case CLEAR_PARENTAL_CONTROL_PIN:
            return {
                ...state,
                parentalControlPin: payload.parentalControlPin,
            };

        case VALID_PARENTAL_CONTROL_PIN:
            return {
                ...state,
                isValidParentalControlPin: payload.isValidParentalControlPin,
                invalidParentalControlPinRetryCount: payload.invalidParentalControlPinRetryCount,
            };

        case INVALID_PARENTAL_CONTROL_PIN:
            return {
                ...state,
                invalidParentalControlPinRetryCount: payload.invalidParentalControlPinRetryCount,
            };

        default:
            return state;
    }
};

export const enterPinOverlayShow = () => (dispatch: Function) => {
    dispatch(enterPinOverlayShowAction());
    Log.i(TAG, `Enter pin overlay is on top.`);
};

export const enterPinOverlayHide = () => (dispatch: Function) => {
    dispatch(enterPinOverlayHideAction());
    Log.i(TAG, `Enter pin overlay disappeared.`);
};

export const readParentalControlPin = (key: number) => (dispatch: Function, getState: Function) => {
    const { pinOverlay } = getState();
    const { parentalControlPin } = pinOverlay;

    if (parentalControlPin.length < PARENTAL_CONTROL_PIN_MAX_LENGTH) {
        parentalControlPin.push(key);
        dispatch(readParentalControlPinAction(parentalControlPin));
    }
};

export const deleteParentalControlPin = () => (dispatch: Function, getState: Function) => {
    const { pinOverlay } = getState();
    const { parentalControlPin } = pinOverlay;

    if (parentalControlPin.length !== 0) {
        parentalControlPin.pop();
        dispatch(deleteParentalControlPinAction(parentalControlPin));
    }
};

export const clearParentalControlPin = () => (dispatch: Function) => {
    dispatch(clearParentalControlPinAction());
};

export const validParentalControlPin = () => (dispatch: Function) => {
    dispatch(validParentalControlPinAction());
    dispatch(clearParentalControlPin());
    storage.clear(Storage.Keys.INVALID_PC_PIN_RETRY_COUNT);
};

export const invalidParentalControlPin = () => (dispatch: Function, getState: Function) => {
    const { pinOverlay } = getState();
    const { invalidParentalControlPinRetryCount } = pinOverlay;
    dispatch(invalidParentalControlPinAction(invalidParentalControlPinRetryCount + 1));
    dispatch(clearParentalControlPin());
    storage.store(Storage.Keys.INVALID_PC_PIN_RETRY_COUNT, invalidParentalControlPinRetryCount + 1);
};

export default pinOverlayReducer;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import './PinOverlay.scss';
import { setNavigationHandler } from '../../store/appl/Appl.reducers';

// TODO: We need to move the interfaces to the core.
interface PinOverlayDialog {
    title: string;
    description: string;
}

interface DialogProps {
    dialog: PinOverlayDialog;
    id: string;
    active: boolean;
    handler: any;
    pin: Array<[]>;
    maxLength: number;
}

const PinOverlay: FunctionComponent<DialogProps> = (props): JSX.Element => {
    const { dialog, id, active, handler, pin, maxLength } = props;
    const [pinOverlayStatus, setPinOverlayStatus] = useState(false);
    const dispatch = useDispatch();
    const pinLength = pin.length;
    const { title, description } = dialog;
    useEffect(() => {
        if (active) {
            if (!pinOverlayStatus) setPinOverlayStatus(true);
            dispatch(setNavigationHandler(handler));
        }
    }, [active]);

    return (
        <div id={id} className={`po-overlay ${!pinOverlayStatus ? 'hidden' : ''}`}>
            <div className={`po-dimmer ${!active ? 'hidden' : ''}`}></div>
            <div className="po-dialog">
                <div className={`po-normal ${active ? 'show-dialog' : 'hide-dialog'}`}>
                    <div className="po-header">
                        <div className="po-title">{title}</div>
                    </div>
                    <div className="po-divider"></div>
                    <div className="po-overlay-description">{description}</div>
                    <div className="po-input-container">
                        <div className="inner-boxes">
                            {generatePinInputBox(maxLength, pinLength)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

PinOverlay.propTypes = {
    dialog: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    handler: PropTypes.any.isRequired,
    pin: PropTypes.array.isRequired,
    maxLength: PropTypes.number.isRequired,
};

const generatePinInputBox = (maxLength: number, pinLength: number) => {
    return new Array(maxLength).fill(0).map((_, index) => {
        return (
            <div
                key={index}
                className={`po-rectangle ${index === maxLength - 1 ? '' : 'space'} ${
                    pinLength === index ? 'highlight' : ''
                }`}
            >
                <span className={`dot ${pinLength > index ? '' : 'hidden'}`}></span>
            </div>
        );
    });
};

export default PinOverlay;

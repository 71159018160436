import { AnyAction } from 'redux';
import { ChannelData } from '../../core/entities/stationdata/ChannelData';
import {
    TPB_SHOW_TRICK_PLAY_BAR,
    TPB_SET_CURRENT_POSITION,
    TPB_SET_CURRENT_TIME,
    TPB_SET_START_END_TIME,
    SIB_SET_DATA_STANDARD_INFO,
    SIB_SHOW_STANDARD_INFO,
    TPB_UPDATE_TRICK_PLAY_BAR_TIMER,
    SIB_UPDATE_STANDARD_INFO_TIMER,
    TPB_SET_PLAYBACK_CONTROLS,
    TPB_UPDATE_PLAYBACK_CONTROLS_TIMER,
    TPB_HIDE_TRICK_PLAY_BAR,
    SIB_HIDE_STANDARD_INFO,
    FIB_SHOW_FULL_INFO,
    FIB_UPDATE_FULL_INFO_TIMEOUT_ID,
    FIB_HIDE_FULL_INFO,
    SET_ACTIONS_STRIP_ACTION_ITEMS_COUNT,
    SET_ACTIONS_STRIP_AVAILABLE_ACTION_ITEM,
    SET_ACTIONS_STRIP_HIGHLIGHT_INDEX,
    SET_IS_OVERLAY_ACTIVE,
} from './InfoBanner.types';

export const setActionsStripHighlightIndexAction = (
    actionsStripHighlightIndex: number,
): AnyAction => ({
    type: SET_ACTIONS_STRIP_HIGHLIGHT_INDEX,
    payload: {
        actionsStripHighlightIndex,
    },
});

export const setAvailableActionItemsAction = (availableActionItems: Array<any>): AnyAction => ({
    type: SET_ACTIONS_STRIP_AVAILABLE_ACTION_ITEM,
    payload: {
        availableActionItems,
    },
});

export const setActionItemsCountAction = (actionItemsCount: number): AnyAction => ({
    type: SET_ACTIONS_STRIP_ACTION_ITEMS_COUNT,
    payload: {
        actionItemsCount,
    },
});

export const setIsOverlayActive = (isOverlayActive: boolean): AnyAction => ({
    type: SET_IS_OVERLAY_ACTIVE,
    payload: { isOverlayActive },
});

export const tpbSetStartEndTimeAction = (startTime: Date, endTime: Date): AnyAction => ({
    type: TPB_SET_START_END_TIME,
    payload: {
        startTime,
        endTime,
    },
});

export const tpbSetCurrentTimeAction = (currentTime: Date): AnyAction => ({
    type: TPB_SET_CURRENT_TIME,
    payload: {
        currentTime,
    },
});

export const tpbSetCurrentPositionAction = (currentPosition: number): AnyAction => ({
    type: TPB_SET_CURRENT_POSITION,
    payload: {
        currentPosition,
    },
});

export const showTrickPlayBarAction = (): AnyAction => ({
    type: TPB_SHOW_TRICK_PLAY_BAR,
    payload: {
        isTrickPlayBarActive: true,
    },
});

export const hideTrickPlayBarAction = (): AnyAction => ({
    type: TPB_HIDE_TRICK_PLAY_BAR,
    payload: {
        isTrickPlayBarActive: false,
    },
});

export const setDataStandardInfoAction = (channelData: ChannelData): AnyAction => ({
    type: SIB_SET_DATA_STANDARD_INFO,
    payload: {
        channelData,
    },
});
export const showStandardInfoBannerAction = (): AnyAction => ({
    type: SIB_SHOW_STANDARD_INFO,
    payload: {
        isStandardInfoActive: true,
        isTrickPlayBarActive: true,
    },
});

export const hideStandardInfoBannerAction = (): AnyAction => ({
    type: SIB_HIDE_STANDARD_INFO,
    payload: {
        isStandardInfoActive: false,
        isTrickPlayBarActive: false,
    },
});
export const updateTrickPlayBarTimeoutIdAction = (trickPlayBarTimeoutId: any): AnyAction => ({
    type: TPB_UPDATE_TRICK_PLAY_BAR_TIMER,
    payload: {
        trickPlayBarTimeoutId,
    },
});

export const updateStandardInfoTimeoutIdAction = (standardInfoTimeoutId: any): AnyAction => ({
    type: SIB_UPDATE_STANDARD_INFO_TIMER,
    payload: {
        standardInfoTimeoutId,
    },
});

export const showPlaybackControlsAction = (): AnyAction => ({
    type: TPB_SET_PLAYBACK_CONTROLS,
    payload: {
        isPlaybackControlsActive: true,
    },
});

export const hidePlaybackControlsAction = (): AnyAction => ({
    type: TPB_SET_PLAYBACK_CONTROLS,
    payload: {
        isPlaybackControlsActive: false,
    },
});

export const updatePlaybackControlsTimeoutIdAction = (
    playbackControlsTimeoutId: any,
): AnyAction => ({
    type: TPB_UPDATE_PLAYBACK_CONTROLS_TIMER,
    payload: {
        playbackControlsTimeoutId,
    },
});

export const showFullInfoAction = (): AnyAction => ({
    type: FIB_SHOW_FULL_INFO,
    payload: {
        isFullInfoActive: true,
    },
});

export const hideFullInfoAction = (): AnyAction => ({
    type: FIB_HIDE_FULL_INFO,
    payload: {
        isFullInfoActive: false,
    },
});

export const updateFullInfoTimeoutIdAction = (fullInfoTimeoutId: any): AnyAction => ({
    type: FIB_UPDATE_FULL_INFO_TIMEOUT_ID,
    payload: {
        fullInfoTimeoutId,
    },
});

export class Constants {
    static AUDIO_FORMAT_DOLBY_DIGITAL = 'Dolby Audio™';

    static AUDIO_DESCRIPTION = 'Audio Description';

    static AUDIO_DESCRIPTION_CONST = 'ad';

    static AUDIO_FORMAT_MPEG1 = 'Stereo (MPEG1)';

    static AUDIO_FORMAT_MPEG2 = 'Stereo (MPEG2)';

    static AUDIO_FORMAT_AAC = 'Stereo (AAC)';

    static AUDIO_LANGUAGE_UNKNOWN = 'unknown';

    static SPACED_DASH = ' - ';

    static SPACE = ' ';

    static ORIGINAL_LANGUAGE = 'Original Language';
}

/* eslint-disable no-console */

export class Log {
    static LogLevel: {
        DEBUG: number;
        ERROR: number;
        INFO: number;
        WARN: number;
    } = {
        DEBUG: 1, // DEBUG, ERROR, WARN
        ERROR: 3, // ERROR
        INFO: 0, // DEBUG, ERROR, INFO, WARN
        WARN: 2, // WARN, ERROR
    };

    private static defaultLogLevel = Log.LogLevel.INFO;

    static setLogLevel(level: number): void {
        Log.defaultLogLevel = level;
    }

    private static getLogPrefix(): string {
        const d = new Date();
        const sDate = `${d.getDate()}`.padStart(2, '0');
        const sMonth = `${d.getMonth() + 1}`.padStart(2, '0');
        const sHour = `${d.getHours()}`.padStart(2, '0');
        const sMin = `${d.getMinutes()}`.padStart(2, '0');
        const sSec = `${d.getSeconds()}`.padStart(2, '0');
        const sMili = `${d.getMilliseconds()}`.padStart(3, '0');

        return `${d.getFullYear()}-${sMonth}-${sDate} ${sHour}:${sMin}:${sSec}.${sMili}  [TiVoPlus] `;
    }

    static d(tag: string, message: string, ...optionalParams: any[]): void {
        if (Log.defaultLogLevel <= Log.LogLevel.DEBUG)
            console.debug(Log.getLogPrefix(), 'D ', tag, message, ...optionalParams);
    }

    static e(tag: string, message: string, ...optionalParams: any[]): void {
        if (Log.defaultLogLevel <= Log.LogLevel.ERROR)
            console.error(Log.getLogPrefix(), 'E ', tag, message, ...optionalParams);
    }

    static i(tag: string, message: string, ...optionalParams: any[]): void {
        if (Log.defaultLogLevel <= Log.LogLevel.INFO)
            console.info(Log.getLogPrefix(), 'I ', tag, message, ...optionalParams);
    }

    static w(tag: string, message: string, ...optionalParams: any[]): void {
        if (Log.defaultLogLevel <= Log.LogLevel.WARN)
            console.warn(Log.getLogPrefix(), 'W ', tag, message, ...optionalParams);
    }
}

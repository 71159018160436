import { Payload, QoE } from '@tivocorp/tivo-webapp-loggers';
import { AppUtil } from '../utils/AppUtil';
import { IDeviceParams } from './IDeviceParams';
import { MetaData } from '../entities/metadata/MetaData';
import { ChannelData } from '../entities/stationdata/ChannelData';
import { IQoEMetrics } from './IQoEMetrics';
import { IQoEContent } from './IQoEContent';
import { IQoEState } from './IQoEState';
import { Log } from './Log';
import { VideoStreamingState } from './VideoStreamingState';
import { DeviceModel } from '../device';
import { SAGE_LOGGING_VERSION } from '../../config';

export class QoEAppLogger {
    private static TAG = 'QoEAppLogger';

    public static MEDIA_HEALTH_EVENT_INTERVAL = 5 * 60 * 1000; // 5 minutes

    private static streamingSessionId: string = AppUtil.getUuid();

    private static watchContentId: string = AppUtil.getUuid();

    private static sessionId: string = AppUtil.getUuid();

    private static mediaStats: IQoEMetrics = {
        playPosition: 0,
        videoStartTime: 0,
        videoPlayingTime: 0,
        videoPlayingCount: 0,
        videoPausingTime: 0,
        videoPausingCount: 0,
        videoBufferingTime: 0,
        videoBufferingCount: 0,
        videoStallingCount: 0,
        videoStallingTime: 0,
        videoTrickplayingTime: 0,
        videoTrickplayingCount: 0,
        videoFramesPresented: 0,
        videoFramesPresentedOffset: 0,
        videoFramesDropped: 0,
        videoFramesDroppedOffset: 0,
        profileShiftCount: 0,
        profilePlayingTime: 0,
        videoBitrate: 0,
        networkBitrate: 0,
        avgVideoBitrate: 0,
        avgNetworkBitrate: 0,
    };

    private static content: IQoEContent = {
        channelId: '',
        channelName: '',
        channelNumber: '',
        collectionId: '',
        collectiontitle: '',
        contentId: '',
        contentTitle: '',
        videoPlayer: DeviceModel.getVideoPlayer(),
        videoPlayerUrl: '',
        videoSource: DeviceModel.getVideoSource(),
    };

    private static state: IQoEState = {
        videoPlayerUrl: '',
        startTime: 0,
        streamingState: VideoStreamingState.STREAMING_STATE_PREFLIGHT,
    };

    static PlaybackEndReasons = {
        ENDED_BY_USER: 'ENDED_BY_USER',
        END_OF_CONTENT: 'END_OF_CONTENT',
        LOST_NETWORK: 'LOST_NETWORK',
        PLAYER_ERROR: 'PLAYER_ERROR',
        AUDIO_TRACK_CHANGE: 'AUDIO_TRACK_CHANGE',
        UNKNOWN: 'UNKNOWN',
    };

    private static PlayerEvents = {
        PLAY: 'play',
        PAUSE: 'pause',
        BUFFERING: 'buffering',
        STALLED: 'stalled',
        TRICK_PLAYING_START: 'trickPlayingStart',
        TRICK_PLAYING_STOP: 'trickPlayingStop',
        CHANGED_AD_TRACK: 'nonCollectibleAction',
        RATE_CHANGE: 'profileShiftCount',
    };

    private static previousPlayerEvent: Event;

    private static endReason: string = QoEAppLogger.PlaybackEndReasons.UNKNOWN;

    private static isWatchContentStarted = false;

    private static isStreamingStarted = false;

    private static qoeStartTime: number;

    // *****************************************************************

    private static createSessionId() {
        QoEAppLogger.sessionId = AppUtil.getUuid();
    }

    private static createStreamingSessionId() {
        QoEAppLogger.streamingSessionId = AppUtil.getUuid();
    }

    private static createWatchContentId() {
        QoEAppLogger.watchContentId = AppUtil.getUuid();
    }

    /** *****************************************************************
     * ////////////////////// QoE Events //////////////////////////////////////////
     * applicationStart
     * watchContentStart
     * streamingStart
     * sessionCreated
     * videoPlayerOpenStream
     * firstFrame
     * mediaHealth
     * streamingEnd
     * watchContentEnd
     */

    static logApplicationStartEvent = () => {
        QoEAppLogger.qoeStartTime = Date.now();
        QoE.logApplicationStart();
    };

    static logWatchContentStartEvent = (isStreaming: boolean = false) => {
        // streaming state set to PREFLIGHT for onStart/error cases.
        // On boundaryChange the state reamins in streaming state.
        if (!isStreaming) {
            QoEAppLogger.state.streamingState = VideoStreamingState.STREAMING_STATE_PREFLIGHT;
        }
        QoEAppLogger.state.startTime = Date.now();

        QoEAppLogger.createWatchContentId();
        let payload = QoEAppLogger.getContentPayload();
        payload = payload.merge(QoEAppLogger.getPlayerStatePayload());
        payload.add('watchContentTransactionId', QoEAppLogger.watchContentId);
        QoE.logWatchContentStart(payload);

        QoEAppLogger.isWatchContentStarted = true;
    };

    static logStreamingStartEvent = () => {
        QoEAppLogger.createStreamingSessionId();
        let payload = QoEAppLogger.getContentPayload();
        payload = payload.merge(QoEAppLogger.getPlayerStatePayload());
        payload.add('streamingSessionTransactionId', QoEAppLogger.streamingSessionId);
        QoE.logStreamingStart(payload);

        QoEAppLogger.isStreamingStarted = true;
    };

    static logSessionCreatedEvent = () => {
        QoEAppLogger.createSessionId();
        QoEAppLogger.state.streamingState = VideoStreamingState.STREAMING_STATE_STARTING;
        const payload = QoEAppLogger.getPlayerStatePayload();
        payload.add('sessionId', QoEAppLogger.sessionId);
        QoE.logSessionCreated(payload);
    };

    static logVideoPlayerOpenStreamEvent = () => {
        const payload = QoEAppLogger.getPlayerStatePayload();
        QoE.logVideoPlayerOpenStream(payload);
    };

    static logFirstFrameEvent = () => {
        // update firstFrame attributes
        QoEAppLogger.mediaStats.videoStartTime = Date.now() - QoEAppLogger.state.startTime;
        // set streamingState to streaming from starting
        QoEAppLogger.state.streamingState = VideoStreamingState.STREAMING_STATE_STREAMING;

        const payload = QoEAppLogger.getPlayerStatePayload();
        QoE.logFirstFrame(payload);
    };

    static logMediaHealthEvent = () => {
        let payload = QoEAppLogger.getContentPayload();
        payload = payload.merge(QoEAppLogger.getPlayerStatePayload());
        payload = payload.merge(QoEAppLogger.getMetricsPayload());
        QoE.logMediaHealth(payload);
    };

    static logStreamingEndEvent = () => {
        if (!QoEAppLogger.isStreamingStarted) {
            Log.w(
                QoEAppLogger.TAG,
                'Ignored. - Trying to log streamingEnd event without streamingStart ...',
            );
            return;
        }
        let payload = QoEAppLogger.getContentPayload();
        payload = payload.merge(QoEAppLogger.getPlayerStatePayload());
        payload = payload.merge(QoEAppLogger.getMetricsPayload());
        payload.add('endReason', QoEAppLogger.endReason);
        QoE.logStreamingEnd(payload);
        // reset streamingStart flag
        QoEAppLogger.isStreamingStarted = false;
    };

    static logWatchContentEndEvent = () => {
        if (!QoEAppLogger.isWatchContentStarted) {
            Log.w(
                QoEAppLogger.TAG,
                'Ignored. - Trying to log watchConentEnd event without watchContetStart ...',
            );
            return;
        }
        let payload = QoEAppLogger.getContentPayload();
        payload = payload.merge(QoEAppLogger.getPlayerStatePayload());
        payload = payload.merge(QoEAppLogger.getMetricsPayload());
        payload.add('endReason', QoEAppLogger.endReason);
        QoE.logWatchContentEnd(payload);
        // reset watchContentStart flag
        QoEAppLogger.isWatchContentStarted = false;
    };

    static setEndReasonAndLogEvent = (reason: string) => {
        QoEAppLogger.endReason = reason;
        switch (reason) {
            case QoEAppLogger.PlaybackEndReasons.ENDED_BY_USER:
            case QoEAppLogger.PlaybackEndReasons.LOST_NETWORK:
            case QoEAppLogger.PlaybackEndReasons.PLAYER_ERROR:
                QoEAppLogger.logStreamingEndEvent();
                QoEAppLogger.logWatchContentEndEvent();
                break;
            case QoEAppLogger.PlaybackEndReasons.AUDIO_TRACK_CHANGE:
                QoEAppLogger.logStreamingEndEvent();
                QoEAppLogger.logStreamingEndEvent();
                break;
            case QoEAppLogger.PlaybackEndReasons.END_OF_CONTENT:
                // set boundaryChange
                QoEAppLogger.logWatchContentEndEvent();
                break;

            // TODO: if user is inactive for 4 hours play back will stop and retrun
            // to previous ui.
            // log end events and appExit segment event if it considered as app exit.
            default:
                Log.e(
                    QoEAppLogger.TAG,
                    'Unhandled stopVideoPlayback action received for the reason - ',
                    reason,
                );
        }
    };

    // ********************************************************************
    // set QOE params global - {base, population}
    //                content
    //                metrics
    //                state
    // ********************************************************************

    private static getMetricsPayload() {
        // no trickplay options available so playposition shlould be always
        // equal to current time.
        QoEAppLogger.mediaStats.playPosition = Math.floor(Date.now() / 1000);
        return new Payload(QoEAppLogger.mediaStats);
    }

    private static getContentPayload() {
        return new Payload(QoEAppLogger.content);
    }

    private static getPlayerStatePayload() {
        return new Payload(QoEAppLogger.state);
    }

    static setGlobalParams(deviceParams: IDeviceParams) {
        const globalParam = <JSON>(<unknown>{
            adTrackingEnabled: deviceParams.adTrackingEnabled,
            appName: deviceParams.appName,
            appVersion: deviceParams.appVersion,
            applicationSessionId: AppUtil.getSessionID(),
            connection: deviceParams.connection,
            deviceId: deviceParams.deviceId,
            deviceType: deviceParams.deviceType,
            deviceTsn: deviceParams.deviceTsn,
            locale: DeviceModel.getLocale(),
            loggingVersion: SAGE_LOGGING_VERSION,
            msoName: deviceParams.msoName,
            msoPartnerId: deviceParams.msoPartnerId,
            timezone: AppUtil.getUserTimezone(),
            userId: deviceParams.userId,
            uuid: AppUtil.getUuid(),
        });

        const globalPayload: Payload = new Payload(globalParam);
        QoE.setGlobals(globalPayload);
        QoE.noConsole();
    }

    static setSageTransportParams(transpport) {
        QoE.getInstance().setTransport(transpport);
    }

    static updateContentPayload(channelData: ChannelData, metaData?: MetaData) {
        QoEAppLogger.content.channelId = channelData.stationId || '';
        QoEAppLogger.content.channelName = channelData.channelName || '';
        QoEAppLogger.content.channelNumber = <string>(channelData.channelNumber || '');
        QoEAppLogger.content.videoPlayerUrl = channelData.playUrl || '';
        QoEAppLogger.state.videoPlayerUrl = QoEAppLogger.content.videoPlayerUrl;
        // Handle cases that metaData fetch delayed/failed.
        if (metaData) {
            QoEAppLogger.content.contentId = metaData.contentId || '';
            QoEAppLogger.content.contentTitle = metaData.title || '';
        }
    }

    static updateMetaData(metaData: MetaData) {
        QoEAppLogger.content.contentId = metaData.contentId || '';
        QoEAppLogger.content.contentTitle = metaData.title || '';
    }

    // compute mediaHealth metrics and update

    static updateHealthStatsMetricsByEvent(event: any) {
        // Ignore duplicate events
        if (
            QoEAppLogger.previousPlayerEvent &&
            event.type === QoEAppLogger.previousPlayerEvent.type
        ) {
            Log.i(
                QoEAppLogger.TAG,
                `Ignoring event: "${event.type}" because it is a duplicate of the previous event and it will not be collected.`,
            );
            return;
        }

        switch (event.type) {
            case QoEAppLogger.PlayerEvents.PLAY:
                QoEAppLogger.mediaStats.videoPlayingCount += 1;
                break;
            case QoEAppLogger.PlayerEvents.PAUSE:
                QoEAppLogger.mediaStats.videoPausingCount += 1;
                break;
            case QoEAppLogger.PlayerEvents.BUFFERING:
                QoEAppLogger.mediaStats.videoBufferingCount += 1;
                break;
            case QoEAppLogger.PlayerEvents.STALLED:
                QoEAppLogger.mediaStats.videoStallingCount += 1;
                break;
            case QoEAppLogger.PlayerEvents.TRICK_PLAYING_START:
                QoEAppLogger.mediaStats.videoTrickplayingCount += 1;
                break;
            case QoEAppLogger.PlayerEvents.RATE_CHANGE:
                QoEAppLogger.mediaStats.profileShiftCount += 1;
                break;
            default:
        }

        QoEAppLogger.aggregateTimeDifferenceToPreviousEvent(event);
        // remember last event
        QoEAppLogger.previousPlayerEvent = event;
    }

    private static aggregateTimeDifferenceToPreviousEvent(event?: Event) {
        if (!QoEAppLogger.previousPlayerEvent) {
            // valid when function called before first event is received
            return;
        }
        const timeDiff = QoEAppLogger.getPlayerEventTimeDifference(event);
        switch (QoEAppLogger.previousPlayerEvent.type) {
            case QoEAppLogger.PlayerEvents.PAUSE:
                QoEAppLogger.mediaStats.videoPausingTime += timeDiff;
                break;
            case QoEAppLogger.PlayerEvents.STALLED:
                QoEAppLogger.mediaStats.videoStallingTime += timeDiff;
                break;
            case QoEAppLogger.PlayerEvents.TRICK_PLAYING_START:
                QoEAppLogger.mediaStats.videoTrickplayingTime += timeDiff;
                break;
            case QoEAppLogger.PlayerEvents.RATE_CHANGE:
                QoEAppLogger.mediaStats.profilePlayingTime += timeDiff;
                break;
            default:
        }
    }

    private static getPlayerEventTimeDifference(event?: Event): number {
        let timeDiff;
        if (event) {
            timeDiff = event.timeStamp - QoEAppLogger.previousPlayerEvent.timeStamp;
        } else {
            // workaround event.timestamp retuns relative time
            // convert epoch time to relative time by (Date.now() - qoeStartTime)
            timeDiff =
                Date.now() -
                (QoEAppLogger.qoeStartTime + QoEAppLogger.previousPlayerEvent.timeStamp);
        }
        return timeDiff;
    }

    private static convertBitsToMbps(value: number) {
        return Math.round((value / 1000 / 1000) * 100) / 100; // Mbps
    }

    // this should be called when we want to reset metrics
    static setIntialMetricsFromPlayerStats(stats: any) {
        // set offset videoFramesPresented and droppedFrames
        QoEAppLogger.mediaStats.videoFramesPresentedOffset = !stats.decodedFrames
            ? 0
            : parseInt(stats.decodedFrames, 10);
        QoEAppLogger.mediaStats.videoFramesDroppedOffset = !stats.droppedFrames
            ? 0
            : parseInt(stats.decodedFrames, 10);
    }

    static updateAllHealthStatsMetrics(stats: any) {
        // cosole.log('PlayerStats =', stats)
        const decodedFrames = parseInt(stats.decodedFrames, 10);
        const droppedFrames = parseInt(stats.droppedFrames, 10);
        const numberOfLevelsPlayed = stats.switchHistory.length;
        // TODO: currently shaka does not give segments info
        QoEAppLogger.mediaStats.videoFramesPresented +=
            decodedFrames - QoEAppLogger.mediaStats.videoFramesPresentedOffset || 0;
        QoEAppLogger.mediaStats.videoFramesDropped +=
            droppedFrames - QoEAppLogger.mediaStats.videoFramesDroppedOffset;

        QoEAppLogger.mediaStats.videoPlayingTime = Math.round(stats.playTime * 1000); // msec
        // bufferingTime is equal to framesPresentedtime + playerLaterncytime + bufferringtime
        QoEAppLogger.mediaStats.videoBufferingTime = Math.round(
            stats.bufferingTime * 1000 + QoEAppLogger.mediaStats.videoStartTime,
        );
        const estimatedBandwidth = stats.estimatedBandwidth || 0;
        QoEAppLogger.mediaStats.networkBitrate = QoEAppLogger.convertBitsToMbps(estimatedBandwidth);
        const videoBitrate = stats.streamBandwidth || 0;
        QoEAppLogger.mediaStats.videoBitrate = QoEAppLogger.convertBitsToMbps(videoBitrate);

        const totalLevelsBandwidth = stats.switchHistory.reduce(function fn(accumulator, level) {
            return accumulator + level.bandwidth;
        }, 0);

        // TODO:
        // currently shakaPlayer does not provide levels of data download so updating
        // estimated bandwidth as avgNetworkBitrate
        QoEAppLogger.mediaStats.avgNetworkBitrate = QoEAppLogger.mediaStats.networkBitrate;

        // Transform the size in Mb.
        // Use 1000 and NOT 1024 because of the difference between Mb (1000Kb) and MiB (1024KiB)
        // Do a check to avoid disivion by 0
        if (numberOfLevelsPlayed > 0) {
            QoEAppLogger.mediaStats.avgVideoBitrate = QoEAppLogger.convertBitsToMbps(
                totalLevelsBandwidth / numberOfLevelsPlayed,
            );
        }

        // collect metrics till now with dummy event
        QoEAppLogger.aggregateTimeDifferenceToPreviousEvent();
    }
}

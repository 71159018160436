import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/root.reducers';
import { TrickPlayBar } from './TrickPlayBar';
import { StandardInfo } from './StandardInfo';
import { FullInfo } from './FullInfo';

interface InfoBannerProps {
    id: string;
}
const InfoBanner: FunctionComponent<InfoBannerProps> = (props): JSX.Element => {
    const { isTrickPlayBarActive, isStandardInfoActive, isFullInfoActive } = useSelector(
        (state: RootState) => state.infoBanner,
    );
    const { id } = props;
    return (
        <React.Fragment>
            {isStandardInfoActive ? <StandardInfo id={`${id}-standard-info-banner`} /> : null}
            {isFullInfoActive ? <FullInfo id={`${id}-full-info-banner`} /> : null}
            {isTrickPlayBarActive ? <TrickPlayBar /> : null}
        </React.Fragment>
    );
};

InfoBanner.propTypes = {
    id: PropTypes.string.isRequired,
};

export default InfoBanner;

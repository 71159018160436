import { Diagnostics } from '@tivocorp/tivo-webapp-loggers';
import { AnalyticsLogger } from '../../logging';
import { ChannelData } from '../../entities/stationdata/ChannelData';
import { IStationDataRepository } from '../../entities/stationdata/IStationDataRepository';
import { APS_CHANNEL_URL, PROVIDER_ID } from '../../../strings';
import { Log } from '../../logging/Log';

export class StationDataRepositoryImpl implements IStationDataRepository {
    url!: string;

    authToken: string;

    stationId: string;

    TAG: string = 'StationDataRepositoryImpl';

    constructor(authToken: string, stationId: string) {
        this.authToken = authToken;
        this.stationId = stationId;
    }

    async getStationData(): Promise<ChannelData[]> {
        try {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: this.authToken,
                },
            };
            // providerID and stationId name should be as per the standard.
            const params = { providerId: PROVIDER_ID, stationId: this.stationId };
            this.url = APS_CHANNEL_URL;
            this.url +=
                (this.url.indexOf('?') === -1 ? '?' : '&') +
                Object.keys(params)
                    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
                    .join('&');
            Log.i(this.TAG, 'APS-Channel-URL->', this.url);
            const res = await fetch(this.url, options);
            const responseJson = await res.json();
            return responseJson.channels.map((data) => new ChannelData(data));
        } catch (error) {
            const message = 'Either the stationid is wrong or stationdata is not coming';
            AnalyticsLogger.logAppError(message);
            Log.e(this.TAG, message, error);
            Diagnostics.error(this.TAG, message, error);
            return [];
        }
    }
}
